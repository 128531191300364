import React from 'react';
import Webcam from 'react-webcam';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Image,
  Box,
} from '@chakra-ui/react';
import { useCapture } from './useCapture';

interface CaptureModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFileCapture: (file: File) => void;
}

export const CaptureModal: React.FC<CaptureModalProps> = ({ isOpen, onClose, onFileCapture }) => {
  const {
    setWebcamRef,
    capturedImage,
    videoConstraints,
    capture,
    handleSave,
    handleRetake,
    onClose: handleClose,
  } = useCapture({
    onFileCapture,
    onClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl" isCentered>
      <ModalOverlay bg="blackAlpha.700" />
      <ModalContent mx={4}>
        <ModalHeader textAlign="left" fontSize="lg" fontWeight="semibold" color="gray.800" pt={4} pb={4}>
          Capture Photo
        </ModalHeader>
        <ModalCloseButton size="md" top="3" color="gray.500" />
        <ModalBody p={8}>
          <Flex direction="column" align="center" w="full">
            {capturedImage ? (
              <Image
                src={capturedImage}
                alt="captured"
                maxH="500px"
                objectFit="contain"
                borderRadius="md"
                shadow="md"
              />
            ) : (
              <Box borderRadius="md" overflow="hidden" shadow="md" w="full" maxW="800px">
                <Webcam
                  audio={false}
                  ref={setWebcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  width="100%"
                  height="100%"
                />
              </Box>
            )}
            <Flex gap={6} mt={8} mb={2} justify="center" w="full">
              {capturedImage ? (
                <>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    borderRadius="lg"
                    fontWeight="light"
                    gridGap="2"
                    size="lg"
                    px={8}
                    onClick={handleSave}
                    mx={2}
                  >
                    Save Photo
                  </Button>
                  <Button
                    variant="outline"
                    size="lg"
                    px={8}
                    borderRadius="lg"
                    fontWeight="normal"
                    bg="white.50"
                    borderColor="gray.200"
                    onClick={handleRetake}
                  >
                    Retake
                  </Button>
                </>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  borderRadius="lg"
                  fontWeight="light"
                  gridGap="2"
                  size="lg"
                  px={12}
                  onClick={capture}
                >
                  Take Photo
                </Button>
              )}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Flex, Table, Tbody, Tr, Td, Text, Divider, Box } from '@chakra-ui/react';
import DisplayObjectInModal from '../../transactions/transaction-details/DisplayObjectInModal';

interface TableDisplayProps {
  data: Record<string, any>;
  statusCode: string;
  latency?: string;
}

const TryoutResponseTable: React.FC<TableDisplayProps> = ({ data, statusCode, latency }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<object | null>(null);
  const [modalHeading, setModalHeading] = useState<string>('');

  const handleCellClick = (key: string, value: any) => {
    if (Array.isArray(value) || typeof value === 'object') {
      setModalContent(value);
      setModalHeading(key);
      setModalOpen(true);
    }
  };

  const renderCellValue = (value: any) => {
    if (value === '' || value === null || value === undefined) {
      return 'NA';
    }
    if (Array.isArray(value) || typeof value === 'object') {
      return (
        <Text
          as="span"
          fontSize="14px"
          fontWeight="normal"
          lineHeight="normal"
          color="blue.700"
          textDecoration="underline"
          _hover={{ color: 'blue.500', cursor: 'pointer' }}
        >
          View Details
        </Text>
      );
    }

    return String(value);
  };

  // Define the keys you want to display at the bottom
  const bottomKeys = [
    'status',
    'requestId',
    'bureauConsentId',
    'timestamp',
    'merchantId',
    'statusCode',
    'consent',
    'consentText',
    'consentType',
    'custID',
    'success',
    'type',
    'referenceId',
    'metadata',
  ];

  // Separate the data into two parts
  const topData = data ? Object.entries(data).filter(([key]) => !bottomKeys.includes(key)) : [];

  return (
    <Flex direction="column" pt="2" alignItems="flex-start" alignSelf="stretch">
      <Flex mb={4} gridGap="3">
        <Flex alignItems="center">
          <Text fontSize="12px" fontWeight="300" lineHeight="normal" mx="2">
            Status Code:
          </Text>
          <Box
            py="1"
            px="2"
            borderRadius="md"
            color={statusCode === '200' ? 'green.500' : 'red.500'}
            fontWeight="light"
            fontSize="xs"
            lineHeight="normal"
            bg={statusCode === '200' ? 'green.50' : 'red.50'}
          >
            {statusCode}
          </Box>
        </Flex>

        {latency && (
          <Flex alignItems="center">
            <Divider orientation="vertical" borderColor="gray.600" borderWidth="1px" mx="2" />
            <Text fontSize="12px" fontWeight="300" lineHeight="normal" mx="2">
              Latency:
            </Text>
            <Box
              as="span"
              py="1"
              px="2"
              borderRadius="md"
              color="gray.600"
              fontWeight="light"
              fontSize="xs"
              lineHeight="normal"
              bg="white.50"
            >
              {latency}
            </Box>
          </Flex>
        )}
      </Flex>
      <Flex overflowX="scroll" w="full" maxH="60vh" bgColor="gray.50">
        <Table
          w="full"
          overflowY="auto"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          borderTop="0px"
          borderTopWidth="0px"
          fontWeight="300"
          color="gray.700"
        >
          <Tbody>
            {/* Render top data first */}
            {topData.map(([key, value]) => (
              <Tr key={key} _hover={{ bg: 'lavender.100' }} height="56px" borderColor="blue.100" borderWidth="1px">
                <Td
                  w="50%"
                  color="gray.700"
                  fontSize="12px"
                  fontWeight="400"
                  fontStyle="normal"
                  borderRightColor="blue.100"
                  borderRightWidth="1px"
                >
                  {key}
                </Td>
                <Td
                  w="50%"
                  color="gray.700"
                  fontSize="12px"
                  fontWeight="400"
                  fontStyle="normal"
                  onClick={() => handleCellClick(key, value)}
                  style={{ cursor: Array.isArray(value) || typeof value === 'object' ? 'pointer' : 'default' }}
                >
                  {renderCellValue(value)}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
      {modalContent && (
        <DisplayObjectInModal
          heading={modalHeading}
          content={modalContent}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </Flex>
  );
};

export default TryoutResponseTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

interface CreateDocumentRequest {
  type: string;
  name: string;
  extension: string;
  metadata: {
    tag: string;
  };
}

export interface ApiResponse {
  createdAt: number;
  documentId: string;
  extension: string;
  merchantId: string;
  metadata: {
    tag: string;
  };
  name: string;
  type: string;
  updatedAt: number;
  uploaded: boolean;
  url?: string;
}

const useCreateDocument = (
  options?: UseMutationOptions<ApiResponse, AxiosError, CreateDocumentRequest>,
): UseMutationResult<ApiResponse, AxiosError, CreateDocumentRequest> => useMutation(createDocument, options);

const createDocument = async ({
  type,
  name,
  extension,
  metadata: { tag },
}: CreateDocumentRequest): Promise<ApiResponse> => {
  const { data } = await bureauPlatformApi.post<ApiResponse>('/documents', {
    type,
    name,
    extension,
    metadata: {
      tag,
    },
  });
  return data;
};

export default useCreateDocument;

import React, { ReactElement, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';

import FileUpload, { UploadingState } from '@/components/file-upload/FileUpload';
import useCreateDocument, { ApiResponse } from '@/screens/manual-capabilities/document-ocr/queries/useCreateDocument';
import useImageUpload from '@/screens/manual-capabilities/document-ocr/queries/useUploadImage';
import { useGetDocument } from '@/screens/manual-capabilities/document-ocr/queries/useGetDocument';
import { imageValidTypes } from '@/constants/constants';

function MultipleFileUploadWrapper({
  formValue,
  setFormValue,
}: {
  formValue?: string;
  setFormValue: (imageUrl: string) => void;
}): ReactElement {
  const initialFile = new File([''], 'workflow');
  const [selectedFile, setSelectedFile] = useState(initialFile);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const imageUpload = useImageUpload();
  const document = useGetDocument();
  const toast = useToast();

  useEffect(() => {
    if (selectedFile.size) {
      setIsFileUploading(true);
      mutateAsync({
        type: selectedFile.type.split('/')[0],
        name: selectedFile.name,
        extension: selectedFile.type.split('/')[1],
        metadata: { tag: 'test data' },
      });
    } else {
      setFormValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile.size]);

  useEffect(() => {
    if (!formValue) {
      setSelectedFile(initialFile);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  const { mutateAsync } = useCreateDocument({
    onSuccess: (res: ApiResponse) => {
      const front = new FormData();
      front.append('attachment', selectedFile, '[PROXY]');
      imageUpload
        .mutateAsync({ documentId: res?.documentId, front })
        .then(() => {
          document.mutateAsync({ documentId: res?.documentId }).then(r => {
            setFormValue(r?.responseURL);
          });
        })
        .finally(() => {
          setIsFileUploading(false);
          setErrorMessage('');
        });
    },
    onError: () => {
      toast({
        description: 'Error encountered while uploading file!',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setErrorMessage('Something went wrong, Please try again!');
      setIsFileUploading(false);
    },
  });

  return isFileUploading ? (
    <UploadingState />
  ) : (
    <FileUpload
      setSelectedFile={setSelectedFile}
      selectedFile={selectedFile}
      validTypes={imageValidTypes}
      message="Upload png or jpg file"
      title=""
      errorMessage={errorMessage}
      invalidFileErrorMessage="Document type is not supported. Please upload .png or .jpg file"
      showUpload
    />
  );
}

export default MultipleFileUploadWrapper;

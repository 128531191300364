import { RichTextContent } from 'contentful';
import { WhenCondition } from '../workflows/workflow-creation/components/workflow-properties/types';
import { AwaitJson, Workflow as WorkflowObject } from '../capabilitiesV2/workflow-creation/types';
import { Constraint, Ids, Operator, OptionType, WorkflowNode } from '../workflows/workflow-creation/types';

export enum WorkflowNodeType {
  CAPABILITY = 1,
  TAG = 2,
  BRANCH = 3,
  START = 4,
  END = 5,
}

export interface WorkflowRenderObj {
  id: string;
  kind: string;
  columns: WorkflowNode[];
  name?: string;
  description?: string;
  isAsync?: boolean;
}
export interface CapabilityPropTypes {
  id: string;
  name: string;
  description: string;
  input: InputParams[];
  output: string[];
  isDragAdded?: boolean;
  isAsync?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object?: WorkflowObject | null;
}

export interface InputParams {
  displayName: string;
  key: string;
}

export interface InputSectionPropTypes {
  sectionName: string;
  data: ResponseInputsV2[];
}

export interface OutputSectionPropTypes {
  sectionName: string;
  data: OutputV2[];
}

export enum ConditionsType {
  accept = 'ACCEPT',
  reject = 'REJECT',
  manual_review = 'MANUAL REVIEW',
}

export interface Conditions {
  [ConditionsType.accept]: WhenCondition[];
  [ConditionsType.reject]: WhenCondition[];
  [ConditionsType.manual_review]: WhenCondition[];
}

export type ConditionsPath = number[];

export enum ConditionGroupType {
  AND = 'AND',
  OR = 'OR',
  AND_GROUP = 'AND_GROUP',
  OR_GROUP = 'OR_GROUP',
}

export interface ConditionType {
  and?: Array<ConditionType>;
  or?: Array<ConditionType>;
  eq?: Array<ConditionType>;
}

export interface ParentMenuItem {
  platformDisplayName?: string;
  id: string;
  header: string;
  subHeader?: string;
  type?: string;
  lablel?: string;
  serviceId?: string;
  outputs?: OutputV2[];
}

export interface ChildItems {
  [id: string]: ParentMenuItem[];
}
export type BranchObjects = {
  selectedBranch: string;
};

export type IndividualBranch = {
  name: string;
  node: ColumnV2;
};

export enum DRAG_ITEM_TYPE {
  CAPABILITY = 'Capability',
}
export type DropItemType = {
  name: string;
  id: string;
  isAsync?: boolean;
  object?: WorkflowObject | null;
  description?: string;
};

export interface WorkflowList {
  workflows?: Workflow[];
  count: number;
}

export interface Workflow {
  name: string;
  workflowId: string;
  createdAt: number;
  updatedAt: number;
  createdBy?: string;
  updatedBy?: string;
  releaseType?: string;
}

export interface WorkflowFilterField {
  outcome?: string[];
  createdAt?: string[];
}

export interface WorkflowListContentProps {
  workflowType?: 'workflows' | 'templates';
  filterFields: WorkflowFilterField;
  data: Workflow[];
  refetch: () => void;
  isFetching: boolean;
  isError: boolean;
  searchTerm: string;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  setSelectedAction: (action: string) => void;
  setWorkflowDetails: (action: Workflow) => void;
}

export interface ListData {
  pageParams: unknown[];
  pages: unknown[];
  listData: Workflow[];
  count: number;
}

export enum ToastMessage {
  WORKFLOW_UPDATED_MESSAGE = 'Workflow Updated successfully',
  WORKFLOW_DELETED_MESSAGE = 'Workflow deleted successfully',
  ERROR_MESSAGE = 'Some error occurred. Please try after some time.',
  EMPTY_SELECTED_LIST_MESSAGE = 'No Workflow selected',
  OVER_SIZED_LIST_MESSAGE = 'Select a single workflow',
}

export interface ContentfulTemplate {
  fields: {
    id: string;
    title: string;
    shortDescription: string;
    supportedCountry: string[];
    tags: string[];
    documentation: RichTextContent;
    thumbnail: {
      fields: {
        file: {
          url: string;
        };
      };
    };
  };
}

export interface ModifyWorkflowDetails {
  name: string;
  workflowId: string;
  active?: boolean;
  action?: string;
  showPopup?: boolean;
  workflowObject?: Workflowv2;
}

export interface GetWorkflowResponse {
  workflowId: string;
  merchantId: string;
  name: string;
  createdAt: number;
  workflowObject: Workflowv2;
  object: Workflowv2;
  useCase: string;
}

export interface ColumnV2 {
  id: string;
  nodes: Array<WorkflowNodeV2>;
  metadata: ColumnMetaData;
}

export interface ColumnMetaData {
  capabilityId?: string;
  capabilityName?: string;
  kind?: string;
  description?: string;
  parentID?: string;
  branch?: string;
  input?: InputParams[];
  outputs?: string[];
  isAsync?: boolean;
}

export interface Workflowv2 {
  columns: Array<ColumnV2>;
}

export interface ResponseInputsV2 {
  description?: string;
  displayName?: string;
  format?: string;
  key?: string;
  location?: string;
  namespace?: string;
  type?: string;
  rowIndex?: number;
  columnIndex?: number;
  elementIndex?: number;
  staticValue?: string;
  isStatic?: boolean;
  isOptional?: boolean;
  locationKind?: string;
  locationType?: string;
}

export interface CategorizedInputs {
  configuredMandatory: ResponseInputsV2[];
  notConfiguredMandatory: ResponseInputsV2[];
  configuredOptional: ResponseInputsV2[];
  notConfiguredOptional: ResponseInputsV2[];
}

export interface OutputV2 {
  key?: string;
  namespace?: string;
  type?: string;
  description?: string;
  format?: string;
  regex?: string;
  constraints?: Constraint;
  template?: string;
  parentKey?: string;
  location?: string;
  operators?: Operator[];
  label?: string;
  value?: string;
  displayName?: string;
  allowedValues?: string[];
  dummyOptions?: OptionType[];
  isDisabled?: boolean;
}

export interface InputsV2 {
  description?: string;
  displayName?: string;
  format?: string;
  key: string;
  locationKind?: string;
  locationId?: string;
  locationType?: string;
  namespace?: string;
  type?: string;
  example?: string;
  allowedValues?: string[];
}

export interface WorkflowNodeV2 {
  id: string;
  kind: string;
  name: string;
  description?: string | '';
  conditions?: Array<WhenCondition>;
  outputs?: Array<OutputV2>;
  dependencies: Array<string>;
  open?: boolean;
  color?: string;
  columnPosition?: number;
  nodePosition?: number;
  outputDependencies?: Array<Ids>;
  className?: string;
  insertColId?: number;
  inputs?: Array<ResponseInputsV2>;
  capabilityId?: string;
  columnIndex?: string;
  nodeIndex?: string;
  supplierId?: string;
  awaits?: AwaitJson[];
  platformDisplayName?: string;
}

export interface UpdateWorkflowDataV2 {
  id?: string;
  workflow: {
    releaseType: string;
    workflowObject?: Workflowv2;
    name?: string;
    active?: boolean;
    useCase?: string;
  };
}

type Branding = {
  logo: string; // Binary Blob of data Should be less than 10MB.
  font: string; // string from system variables
  primaryColor: string;
  secondaryColor: string;
};

type TextConfig = {
  fontSize: number;
  color: string;
};

type TextObjects = {
  title: TextConfig;
  subtitle: TextConfig;
  bodyTitle: TextConfig;
  content: TextConfig;
};

type Buttons = {
  [key: string]: unknown;
};

type Background = {
  color: string;
};

export type Config = {
  branding: Branding;
  text: {
    color: string;
    objects: TextObjects;
  };
  buttons: Buttons;
  background: Background;
};

export type QuickLinkData = {
  name: string;
  workflowId: string;
  showResponseOutput: boolean;
  transactionLimit: number;
  isEnabled: boolean;
  redirectionUrl?: string;
};

export type QuickLinkResponse = {
  id: string;
  name: string;
  workflowId: string;
  workflowName?: string;
  showResponseOutput: boolean;
  transactionLimit: number;
  isEnabled: boolean;
  sdkToken: string;
};

export type QuickLinkSaveResponse = {
  id: string;
  url: string;
};

export type PaginatedQuickLinkResponse = {
  data: QuickLinkResponse[];
  count: number;
  limit: number;
  offset: number;
};

export type QuickLinkQueryParams = {
  search?: string;
  limit?: number;
  offset?: number;
  // Add any other filter parameters you might need
};

import React, { ReactElement, UIEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Flex, Box, Text, useToast, Grid } from '@chakra-ui/react';
import { Copy, DotsThreeVertical } from 'phosphor-react';

import EmptyListComponent from '@/components/EmptyListComponent';
import { ToastMessage } from '@/components/copy-button/types';
import { colors } from '@/utils/colors';
import { UserAction } from '@/types/common';
import FactsLoader from '@/components/loader/Loader';

import { timeSince } from '@/screens/capabilitiesV2/helpers/dateHelper';
import { useQueryClient } from 'react-query';
import { useResetRecoilState } from 'recoil';
import EmptySearchResult from '@/components/EmptySearchResult';
import { WorkflowListContentProps, Workflow } from '../types';
import { WorkflowAtom } from '../states/workflowAtom';
import { BranchAtom } from '../states/branchAtom';
import { ConditionsAtom } from '../states/conditionsAtom';
import { SelectedNodeAtom } from '../states/selectedNodeAtom';
import { EndnodeAtom } from '../states/endnodeAtom';
import { WorkflowNameAtom } from '../states/workflowName';
import ErrorStateComponent from '../../workflows/components/ErrorStateComponent';

export const rowActions = [
  { name: 'Edit', type: UserAction.EDIT, id: 'edit' },
  { name: 'Duplicate', type: UserAction.DUPLICATE, id: 'duplicate' },
  { name: 'Export', type: UserAction.EXPORT, id: 'export' },
  { name: 'Delete', type: UserAction.DELETE, id: 'delete' },
];

const WorkFlowListContent = ({
  workflowType = 'workflows',
  filterFields,
  data,
  isError,
  refetch,
  isFetching,
  searchTerm,
  hasNextPage,
  fetchNextPage,
  setSelectedAction,
  setWorkflowDetails,
}: WorkflowListContentProps): ReactElement => {
  const isFilterApplied = !filterFields.outcome?.includes('*') || filterFields.createdAt?.length || searchTerm;

  const handleScroll = async (evt: UIEvent<HTMLDivElement>): Promise<void> => {
    const { scrollHeight, scrollTop, clientHeight } = evt?.currentTarget;
    if (!isFetching && scrollHeight - scrollTop - 100 <= clientHeight) {
      if (fetchNextPage && hasNextPage) {
        await fetchNextPage();
      }
    }
  };

  const customEmptyBody = (): ReactElement => (
    <EmptyListComponent
      // onButtonClick={onCreateWorkflow}
      heading="Hey, you have not created any workflow.!"
      // buttonText="Create Workflow"
      text="You may create a new Workflow by clicking + Create Workflow."
    />
  );

  if (isError) {
    return (
      <>
        <Flex align="center" justify="center" h="100%">
          <ErrorStateComponent
            imageSrc="/assets/workflow_error_state.svg"
            imageWidth="75px"
            heading="Something went wrong!"
            text="It seems there is a technical issue that is preventing data from loading."
            onRetry={() => {
              refetch();
            }}
            styles={{ w: '42%', transform: 'translateY(-15%)' }}
            isRetryEnabled
          />
        </Flex>
      </>
    );
  }

  return (
    <Flex direction="column" w="full" h="full" justifyContent="center">
      {isFetching ? (
        <FactsLoader />
      ) : (
        <>
          {data.length > 0 && (
            <Flex wrap="wrap" overflow="auto" h="full" onScroll={event => handleScroll(event)}>
              <Grid templateColumns="repeat(auto-fill, minmax(20rem, 1fr))" gridGap="4" w="full" h="fit-content">
                {data.map(workflow => (
                  <WorkflowItem
                    key={workflow?.workflowId}
                    workflowType={workflowType}
                    workflow={workflow}
                    setSelectedAction={setSelectedAction}
                    setWorkflowDetails={setWorkflowDetails}
                  />
                ))}
              </Grid>
            </Flex>
          )}

          {/* Empty state for search results */}
          {!data.length && isFilterApplied && <EmptySearchResult text="No Result Found!" styles={{ mt: -4 }} />}

          {/* Empty State for List */}
          {!data.length && !isFilterApplied && !isFetching && (
            <Flex justifyContent="center" mt={12}>
              <Box w="352px">{customEmptyBody()}</Box>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

export const WorkflowItem = ({
  workflowType = 'workflows',
  workflow,
  setSelectedAction,
  setWorkflowDetails,
}: {
  workflowType: 'workflows' | 'templates';
  workflow: Workflow;
  setSelectedAction: (action: string) => void;
  setWorkflowDetails: (action: Workflow) => void;
}): ReactElement => {
  const queryClient = useQueryClient();
  const resetWorkflow = useResetRecoilState(WorkflowAtom);
  const resetBranch = useResetRecoilState(BranchAtom);
  const resetConditions = useResetRecoilState(ConditionsAtom);
  const resetEndNode = useResetRecoilState(EndnodeAtom);
  const resetSelectedNodeItem = useResetRecoilState(SelectedNodeAtom);
  const resetWorkflowName = useResetRecoilState(WorkflowNameAtom);
  const [itemHovered, setItemHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const ref = useDetectClickOutside({ onTriggered: () => setShowMenu(false) });
  const history = useHistory();
  const toast = useToast();

  const onWorkflowClick = (): void => {
    const path =
      workflowType === 'templates'
        ? `/workflows/templates/${workflow?.workflowId}/edit`
        : `/workflows/${workflow?.workflowId}/edit`;
    queryClient.removeQueries({ queryKey: 'capabilityList' });
    resetWorkflow();
    resetBranch();
    resetConditions();
    resetEndNode();
    resetSelectedNodeItem();
    resetWorkflowName();
    history.push(path);
  };

  const copyText = (): void => {
    toast({
      title: ToastMessage.COPIED_TO_CLIPBOARD,
      status: 'info',
      duration: 1000,
      isClosable: true,
    });
    navigator.clipboard.writeText(workflow?.workflowId);
  };

  return (
    <Flex
      h="full"
      direction="column"
      justifyContent="space-between"
      borderWidth={1}
      borderColor="gray.200"
      _hover={{ boxShadow: '0px 6px 12px rgba(16, 22, 86, 0.1)' }}
      bg="white"
      borderRadius="lg"
      cursor="pointer"
      onClick={onWorkflowClick}
      onMouseEnter={() => setItemHovered(true)}
      onMouseLeave={() => {
        setItemHovered(false);
        setShowMenu(false);
      }}
    >
      <Box p={4}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text
            color="gray.900"
            fontWeight="medium"
            fontSize="lg"
            textOverflow="ellipsis"
            // whiteSpace="nowrap"
            overflow="hidden"
            width="92%"
            title={workflow.name}
          >
            {workflow?.name}
          </Text>
          {workflowType !== 'templates' && (
            <Flex direction="column" ref={ref} position="relative">
              {itemHovered && (
                <DotsThreeVertical
                  size={20}
                  color={colors.gray[500]}
                  onClick={e => {
                    e.stopPropagation();
                    setShowMenu(!showMenu);
                  }}
                />
              )}
              {showMenu && (
                <Flex
                  direction="column"
                  position="absolute"
                  top="20px"
                  right="0"
                  bg="white"
                  py={2}
                  px={2}
                  zIndex={1}
                  borderRadius="lg"
                  w="120px"
                  boxShadow="1px 1px 8px rgba(31, 51, 203, 0.09)"
                  fontWeight="light"
                >
                  {rowActions &&
                    rowActions.map(opt => (
                      <Box
                        key={opt.type}
                        pl={2}
                        py={1}
                        fontSize="sm"
                        cursor="pointer"
                        _hover={{ bg: 'white.50', borderRadius: 'lg' }}
                        color={opt.type === 'delete' ? 'red.600' : 'initial'}
                        id={opt.id}
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedAction(opt?.type);
                          setWorkflowDetails(workflow);
                        }}
                      >
                        {opt.name}
                      </Box>
                    ))}
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
        <Flex alignItems="center" mr={4}>
          <Text
            fontSize="sm"
            color="gray.600"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            fontWeight="light"
            width="92%"
            mt={1}
          >
            {workflow?.workflowId}
          </Text>
          {itemHovered && (
            <Copy
              color={colors.gray[500]}
              onClick={e => {
                e.stopPropagation();
                copyText();
              }}
            />
          )}
        </Flex>
      </Box>
      <Flex p={4} bg="white.50" borderBottomRadius="lg" justifyContent="space-between" alignItems="center">
        <Text fontSize="xs" color="gray.500" fontWeight="light">
          Edited {timeSince(workflow.updatedAt)} ago
        </Text>
      </Flex>
    </Flex>
  );
};

export default WorkFlowListContent;

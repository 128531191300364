import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Box, Text } from '@chakra-ui/react';

export interface SimpleTabItem {
  label: string;
  content: React.ReactNode;
  id: string;
}

export interface SimpleTabsProps {
  tabs: SimpleTabItem[];
}

const SimpleTabs: React.FC<SimpleTabsProps> = ({ tabs }) => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <Tabs color="gray.600" variant="unstyled" onChange={index => setTabIndex(index)} h="calc(100% - 160px)">
      <TabList>
        {tabs.map((tab, index) => (
          <Tab key={tab.id} pl="0" _selected={{ borderBottom: 'none', boxShadow: 'none' }} fontSize="sm" pb={0}>
            <Flex direction="column" width="fit-content">
              <Text
                pb={2}
                color={tabIndex === index ? 'blue.500' : 'gray.600'}
                fontWeight={tabIndex === index ? 'medium' : 'light'}
                fontSize="sm"
              >
                {tab.label}
              </Text>
              <Box
                borderWidth={2}
                borderColor={tabIndex === index ? 'blue.500' : 'transparent'}
                bg={tabIndex === index ? 'blue.500' : 'transparent'}
                borderRadius="lg"
                transition="all 100ms ease-in"
              />
            </Flex>
          </Tab>
        ))}
      </TabList>
      <TabPanels h="full">
        {tabs.map(tab => (
          <TabPanel key={tab.id} mt="6" p="0" h="full">
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default SimpleTabs;

import React, { ReactElement, useRef, SetStateAction, MouseEvent, useState } from 'react';
import { Trash, File } from 'phosphor-react';
import {
  Flex,
  Text,
  useToast,
  IconButton,
  Image,
  Box,
  CircularProgress,
  CircularProgressLabel,
  FlexProps,
  useDisclosure,
} from '@chakra-ui/react';
import { colors } from '@/utils/colors';
import { fileSizes } from '@/constants/constants';
import { renderFileSize } from '@/screens/bulk-verification/helpers';
import { ToastMessage } from './types';
import { CaptureModal } from './CaptureModal';

const FileUpload = ({
  setSelectedFile,
  selectedFile,
  validTypes,
  message,
  style,
  title = 'Upload Document',
  errorMessage = '',
  showUpload,
  invalidFileErrorMessage,
  progress,
}: {
  setSelectedFile: Function;
  selectedFile: File;
  validTypes: string[];
  message: string;
  style?: object;
  title?: string;
  errorMessage?: string;
  showUpload?: boolean;
  invalidFileErrorMessage?: string;
  progress?: number;
}): ReactElement => {
  const initialFileObj = {
    name: '',
    size: 0,
  };
  const toast = useToast();
  const [invalidError, setInvalidError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const setFile = (file: File): void => {
    if (validateFile(file as File)) {
      setSelectedFile(file as SetStateAction<{ name: string; size: number }>);
    } else if (invalidFileErrorMessage) {
      setInvalidError(true);
      setSelectedFile(file as SetStateAction<{ name: string; size: number }>);
    } else {
      toast({
        title: ToastMessage.INVALID_FILE_MESSAGE,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setSelectedFile(initialFileObj);
    }
  };

  const validateFile = (file: File): boolean => validTypes.indexOf(file.type) !== -1;

  const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0';
    const initialByte = 1024; // kilobyte
    const decimalValues = decimals < 0 ? 0 : decimals; //  add point value
    const kiloBytes = Math.floor(Math.log(bytes) / Math.log(initialByte));
    return `${parseFloat((bytes / initialByte ** kiloBytes).toFixed(decimalValues))} ${fileSizes[kiloBytes]}`; // size in byte
  };

  const fileDrop = (event: React.DragEvent): void => {
    event.preventDefault();
    const file = event?.dataTransfer.files?.[0];
    setFile(file);
  };

  const onFileUpload = (event: React.ChangeEvent): void => {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    setFile(file);
  };

  const onFileClick = (e: MouseEvent): void => {
    (e.target as HTMLInputElement).value = '';
  };

  const dropZoneStyles: FlexProps = {
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 4,
    height: 200,
    borderRadius: 'lg',
    border: '2px dashed',
    borderColor: errorMessage ? 'red.500' : 'blueGray.100',
    bg: errorMessage ? 'red.200' : 'lavender.100',
    ...style,
  };

  return (
    <>
      {title && (
        <Text fontSize="sm" fontWeight={500} mb={2}>
          {title}
        </Text>
      )}
      {showUpload && (!selectedFile?.size || errorMessage) && (
        <Flex
          {...dropZoneStyles}
          onDragOver={e => e?.preventDefault()}
          onDragEnter={e => e?.preventDefault()}
          onDragLeave={e => e?.preventDefault()}
          onDrop={fileDrop}
          cursor="pointer"
          onClick={() => inputRef?.current?.click()}
        >
          {errorMessage ? (
            <Image src="/assets/icons/error_response.svg" mb={3} />
          ) : (
            <Image src="/assets/icons/csvExport.svg" w={10} h={7} mb={3} />
          )}
          <Text color="gray.900" fontWeight="light" fontSize="xs" maxH="30%" overflowY="auto">
            {errorMessage || message.split('. ')[0]}
          </Text>
          <Text color="gray.900" fontWeight="light" fontSize="xs" maxH="30%" overflowY="auto" mb="2">
            {message.split('. ')[1]}
          </Text>
          <Flex fontWeight="light" fontSize="xs" color="gray.500">
            Drag & Drop or
            <Text
              as="span"
              px={1}
              cursor="pointer"
              textDecoration="underline"
              fontWeight="normal"
              fontSize="xs"
              color="blue.500"
            >
              browse
            </Text>
            <Text
              as="span"
              px={1}
              cursor="pointer"
              textDecoration="underline"
              fontWeight="normal"
              fontSize="xs"
              color="blue.500"
              onClick={e => {
                e.stopPropagation();
                onOpen();
              }}
            >
              capture
            </Text>
            {errorMessage && ' another one'}
          </Flex>
          <input
            type="file"
            ref={inputRef}
            accept={validTypes?.join(',')}
            style={{ display: 'none' }}
            onChange={onFileUpload}
            onClick={e => onFileClick(e)}
          />
        </Flex>
      )}

      {selectedFile.size > 0 && !!progress && progress < 100 && (
        <Flex {...dropZoneStyles}>
          <CircularProgress value={progress} size="50px" thickness="4px" color="blue.500">
            <CircularProgressLabel fontSize="sm" fontWeight="normal" lineHeight="130%">
              {progress + 1}%
            </CircularProgressLabel>
          </CircularProgress>
          <Text color="gray.900" fontWeight="light" fontSize="xs" maxH="30%" overflowY="auto" mb="2" mt="3">
            Uploading File
          </Text>
          <Flex fontWeight="light" fontSize="xs" color="gray.500">
            {renderFileSize(selectedFile.size ?? 0)}
          </Flex>
        </Flex>
      )}

      {!!selectedFile?.size && !errorMessage ? (
        <Flex justifyContent="space-between" alignItems="center" py={3}>
          <Flex bg="blue.500" p={1} borderRadius="50%">
            <File size={14} color="white" weight="fill" />
          </Flex>
          <Flex pl={2} direction="column" w="100%">
            <Text fontSize="sm" fontWeight={500}>
              {selectedFile?.name}
            </Text>
            <Text fontSize="xs" fontWeight={300}>
              {formatBytes(Number(selectedFile?.size))}
            </Text>
          </Flex>
          <IconButton
            minW="inherit"
            maxW={10}
            h={10}
            bg="none"
            fontSize="20px"
            aria-label="back"
            icon={<Trash size={20} color={colors.blue[500]} />}
            _hover={{ bg: 'none' }}
            _active={{ bg: 'none' }}
            onClick={() => {
              setSelectedFile(initialFileObj);
            }}
          />
        </Flex>
      ) : null}
      {(invalidFileErrorMessage || errorMessage) && selectedFile?.size > 0 && (
        <Box fontSize="xs" color="red.500">
          {invalidError ? invalidFileErrorMessage : errorMessage}
        </Box>
      )}

      <CaptureModal isOpen={isOpen} onClose={onClose} onFileCapture={setFile} />
    </>
  );
};

export const UploadingState = (): ReactElement => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      p={4}
      height={200}
      borderRadius="lg"
      bg="blue.50"
      border="1px dashed"
      borderColor="purple.200"
      cursor="pointer"
    >
      <Image src="/assets/loaderGif.gif" w={10} mb={3} />
      <Text color="gray.900" fontWeight="light" fontSize="xs">
        Uploading...
      </Text>
    </Flex>
  );
};

export default FileUpload;

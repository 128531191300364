import React from 'react';
import SimpleTabs, { SimpleTabItem } from '@/components/tabs/SimpleTabs';
import { Button, Box, Flex, Text } from '@chakra-ui/react';
import JsonDisplay from './JsonDisplay';
import TryoutResponseTable from './TryoutResponseTable';

const TryoutTabs = ({
  response,
  onButtonClick,
  statusCode,
  latency,
}: {
  response: Record<string, unknown>;
  onButtonClick: () => void;
  statusCode: string;
  latency?: string;
}): React.ReactElement => {
  const tabs: SimpleTabItem[] = [
    {
      label: 'Table View',
      content: <TryoutResponseTable data={response} statusCode={statusCode as string} latency={latency} />,
      id: 'table-view',
    },
    {
      label: 'JSON',
      content: <JsonDisplay data={response} />,
      id: 'json',
    },
  ];
  return (
    <Box w="full">
      <Flex pb="5" w="full" alignItems="center" justifyContent="space-between">
        <Text color="gray.600" fontSize="14px" fontWeight="500" lineHeight="normal">
          Output:
        </Text>
        <Button
          onClick={onButtonClick}
          fontWeight="normal"
          type="button"
          gridGap="2"
          borderWidth={1}
          color="gray.800"
          borderRadius="lg"
          fontSize="sm"
          bg="white.50"
          borderColor="gray.200"
        >
          Reset
        </Button>
      </Flex>
      <SimpleTabs tabs={tabs} />
    </Box>
  );
};

export default TryoutTabs;

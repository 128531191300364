import { useState, useCallback } from 'react';
import Webcam from 'react-webcam';

interface UseCaptureModalProps {
  onFileCapture: (file: File) => void;
  onClose: () => void;
}

interface CaptureHookReturn {
  webcamRef: Webcam | null;
  setWebcamRef: React.Dispatch<React.SetStateAction<Webcam | null>>;
  capturedImage: string | null;
  videoConstraints: {
    width: number;
    height: number;
    facingMode: string;
  };
  capture: () => void;
  handleSave: () => void;
  handleRetake: () => void;
  onClose: () => void;
}

export const useCapture = ({ onFileCapture, onClose }: UseCaptureModalProps): CaptureHookReturn => {
  const [webcamRef, setWebcamRef] = useState<Webcam | null>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const videoConstraints = {
    width: 720,
    height: 480,
    facingMode: 'user',
  };

  const capture = useCallback(() => {
    if (webcamRef) {
      const imageSrc = webcamRef.getScreenshot();
      setCapturedImage(imageSrc);
    }
  }, [webcamRef]);

  const handleSave = (): void => {
    if (capturedImage) {
      fetch(capturedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'captured-image.jpg', { type: 'image/jpeg' });
          onFileCapture(file);
          onClose();
          setCapturedImage(null);
        });
    }
  };

  const handleRetake = (): void => {
    setCapturedImage(null);
  };

  const handleClose = useCallback(() => {
    setCapturedImage(null);
    onClose();
  }, [onClose]);

  return {
    webcamRef,
    setWebcamRef,
    capturedImage,
    videoConstraints,
    capture,
    handleSave,
    handleRetake,
    onClose: handleClose,
  };
};

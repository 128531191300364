import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';

export type Strategy = {
  merchantId: string;
  serviceId: string;
  serviceStrategy: string;
  mbCode: string;
  endpoint: string;
  accessStatus: string;
};

export type V1Service = {
  id: string;
  displayName: string;
  mbCode: string;
  endpoint: string;
};

export type ServiceConfig = {
  strategies: Strategy[];
  v1Services: V1Service[];
};

export const useGetMerchantAccessDetails = (): UseQueryResult<ServiceConfig, AxiosError> => {
  return useQuery(['get-merchant-endpoints', '/documentation'], getMerchantAccessDetails(), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

const getMerchantAccessDetails = (): (() => Promise<ServiceConfig>) => {
  const merchantId = bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID'];
  return async () => {
    const res = await bureauPlatformApi.get<ServiceConfig>(`/merchant-service-strategies?merchantId=${merchantId}`);
    return res.data;
  };
};

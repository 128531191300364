import React, { ReactElement } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Heading,
  Text,
  Input,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useImportWorkflow } from './useImportWorkflow';

interface ImportWorkflowModalProps {
  onCancel: () => void;
  onClose: () => void;
}

export const ImportWorkflowModal = ({ onCancel, onClose }: ImportWorkflowModalProps): ReactElement => {
  const { selectedFile, isLoadingWorkflowsave, handleFileChange, handleSubmit } = useImportWorkflow({
    onClose,
  });

  return (
    <Modal isOpen onClose={onCancel} isCentered size="lg">
      <ModalOverlay bg="transparentGray.100" />
      <ModalContent py={2}>
        <ModalHeader fontSize="md" fontWeight="500">
          Import Workflow
          <Heading as="h6" fontSize="sm" fontWeight={300} color="gray.500" mt={4}>
            Upload a JSON file containing your workflow configuration.
          </Heading>
        </ModalHeader>
        <ModalCloseButton top={4} color="gray.600" _focus={{ border: 'none' }} />
        <Formik initialValues={{ file: null }} onSubmit={handleSubmit}>
          {() => (
            <Form>
              <ModalBody>
                <FormControl>
                  <FormLabel fontSize="sm" fontWeight="400">
                    Workflow File
                  </FormLabel>
                  <Input
                    type="file"
                    accept=".json"
                    onChange={handleFileChange}
                    fontSize="sm"
                    fontWeight="light"
                    p={1}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="lg"
                  />
                  <Text fontSize="xs" color="gray.500" mt={2}>
                    Only JSON files are supported
                  </Text>
                </FormControl>
              </ModalBody>
              <ModalFooter pt={6}>
                <Button
                  h="38px"
                  w="124px"
                  color="blue.800"
                  bg="purple.300"
                  borderRadius="lg"
                  fontSize="sm"
                  fontWeight="300"
                  _hover={{ opacity: '0.8' }}
                  mr={3}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  h="38px"
                  w="124px"
                  color="white"
                  bg="blue.500"
                  disabled={!selectedFile}
                  fontSize="sm"
                  borderRadius="lg"
                  fontWeight="300"
                  _hover={selectedFile ? { opacity: '0.8', background: 'blue.500' } : {}}
                  _disabled={{ color: 'gray.600', bg: 'gray.200' }}
                  isLoading={isLoadingWorkflowsave}
                >
                  Import
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ImportWorkflowModal;

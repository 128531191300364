import { Elements, Node } from 'react-flow-renderer';
import { WhenCondition } from './components/workflow-properties/types';

export interface Workflow {
  columns: Array<Column>;
}

export interface Column {
  id: string;
  nodes: Array<WorkflowNode>;
}

export interface WorkflowNode {
  id: string;
  kind: string;
  name: string;
  description?: string | '';
  conditions?: Array<WhenCondition>;
  outputs?: Array<Output>;
  dependencies: Array<string>;
  open?: boolean;
  color?: string;
  columnPosition?: number;
  nodePosition?: number;
  outputDependencies?: Array<Ids>;
  className?: string;
  insertColId?: number;
  inputs?: Array<ResponseInputs>;
  capabilityId?: string;
  columnIndex?: string;
  nodeIndex?: string;
  platformDisplayName?: string;
  displayName?: string;
}

export interface Constraint {
  numerical_max?: number | null;
  numerical_min?: number | null;
  length_max?: number | null;
  length_min?: number | null;
}

export interface NodeData {
  id?: string;
  icon?: string;
  name?: string;
  open?: boolean;
  columnPosition?: number;
  nodePosition?: number;
  color?: string;
  showRightHandle?: boolean;
  kind?: string;
}

export interface OptionType<T = string> {
  label: string;
  value: T;
}

export interface Ids {
  columnPosition: number;
  nodePosition: number;
}

export interface ColumnPosition {
  type: string;
  columnPosition: number | null;
  nodePosition: number | null;
  newColumn?: boolean;
}

export interface CreateWorkflowRequest {
  name: string;
  workflowObject?: Workflow;
  releaseType?: string;
  useCase?: string;
}

export interface WorkflowResponse {
  workflowId: string;
  name: string;
  createdAt: number;
  active: boolean;
}

export interface GetServicesResponse {
  suppliers: Service[];
}

export interface Output {
  key?: string;
  namespace?: string;
  type?: string;
  description?: string;
  format?: string;
  regex?: string;
  constraints?: Constraint;
  template?: string;
  parentKey?: string;
  location?: string;
  operators?: Operator[];
  label?: string;
  value?: string;
  displayName?: string;
  allowedValues?: string[];
  dummyOptions?: OptionType[];
  isDisabled?: boolean;
  platformDisplayName?: string;
}

export interface Inputs {
  description?: string;
  displayName?: string;
  format?: string;
  key?: string;
  locationKind?: string;
  locationId?: string;
  locationType?: string;
  namespace?: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staticValue?: any;
  isStatic?: boolean;
  allowedValues?: (boolean | string)[];
  apiName?: string;
  platformDisplayName?: string;
}

export interface ResponseInputs {
  description?: string;
  displayname?: string;
  format?: string;
  key?: string;
  location?: string;
  namespace?: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staticValue?: any;
  isStatic?: boolean;
  allowedValues?: (boolean | string)[];
}

export interface SortOption {
  [key: string]: string | number;
}

export interface Operator {
  label?: string;
  key?: string;
  value?: string;
  hiddenParams?: boolean;
}

export interface Service {
  id?: string;
  label?: string;
  name?: string;
  description?: string;
  value?: string;
  outputParameters?: Output[];
  type?: string;
  action?: string;
  displayName?: string;
  color?: string;
  active?: boolean;
  supplierId?: string;
  platformDisplayName?: string;
  apiName?: string;
}

export interface KeyValue {
  key: string;
  value: string;
}

export interface Options {
  label: string;
  value: string;
}

export interface GetWorkflowResponse {
  workflowId: string;
  merchantId: string;
  name: string;
  createdAt: number;
  isAsyncExecution: boolean;
  workflowObject: Workflow;
}

export interface UpdateWorkflowData {
  id?: string;
  workflow: {
    workflowObject?: Workflow;
    name?: string;
    active?: boolean;
    isAsyncExecution?: boolean;
  };
}

export interface StepDetails {
  name: string;
  platformDisplayName?: string;
  color?: string;
}

export interface ConditionsData {
  id?: string;
  type?: string;
  name?: string;
  columnPosition?: number;
  nodePosition?: number;
}

export interface Tag {
  label: string;
  value: string;
  outputs?: Output[];
}

export interface GetAttributesResponse {
  inputParameters: Output[];
}

export interface InputAttributeOptions {
  key: string;
  label: string;
  value: string;
  type: string;
}

export interface Position {
  columnPosition: number;
  nodePosition: number;
}

export interface ModifyNodeDetails {
  type: string;
  oldName: string;
  oldId: string;
  action: string;
  color?: string;
  awaits?: AwaitJson[];
  inputs?: Inputs[];
  description: string;
}

export enum NodeKind {
  input = 'start',
  service = 'source',
  tag = 'tag',
  accept = 'end_accepted',
  reject = 'end_rejected',
  manualReview = 'end_manual_review',
  await = 'AWAIT',
}

export interface BoxField {
  kind: string;
  id: string;
  type: string;
  operator: string;
  value: string | boolean | number;
  functionOperator: string;
  staticDynamicField?: string;
  dynamicKind?: string;
  dynamicId?: string;
  dynamicType?: string;
}

export interface ModifyWorkflowDetails {
  name: string;
  workflowId: string;
  active?: boolean;
  action?: string;
  showPopup?: boolean;
  isAsyncExecution?: boolean;
  workflowObject?: Workflow;
}

export interface EdgeButtonPosition {
  sourceX?: number;
  insertColumnId: number;
}

export enum NodeType {
  input = 'input',
  output = 'output',
  source = 'source',
  addToColumn = 'addToColumn',
}

export enum EdgeType {
  smoothstep = 'smoothstep',
  buttonEdge = 'buttonEdge',
}

export enum EndState {
  ACCEPT = 'accept',
  REJECT = 'reject',
  MANUAL_REVIEW = 'manual_review',
}

export interface ChartProps<T = Elements> {
  elements: T;
  saveElements: (cb: (prevElements: Elements) => Elements) => void;
  onNodeClick: (node: Node) => void;
  onPanelClick: () => void;
  columnsLength: number;
}

export enum ToastMessage {
  WORKFLOW_SAVED_MESSAGE = 'Workflow saved successfully',
  ERROR_MESSAGE = 'Some error occurred. Please try again.',
  CONDITION_SAVED_MESSAGE = 'Conditions saved successfully',
}

export interface Condition {
  condition: ConditionItem;
}

export interface ConditionItem {
  type: string;
  items: ConditionGroupItem[];
}

export interface ConditionGroupItem {
  kind: string;
  id: string;
  type: string;
  operator: string;
  value: string | number | boolean;
  isDynamic: boolean;
  dynamicItem: DynamicItem;
  group: ConditionItem;
  isFunctionOperator: boolean;
  functionOperator: string;
  functionItems: FunctionItem[];
  rawInput?: string;
}

export interface DynamicItem {
  id: string;
  kind: string;
  type: string;
}

export interface FunctionItem {
  kind: string;
  id: string;
  type: string;
  value: string | number | boolean;
}

export interface AwaitData {
  name: string;
  params: string[];
}

export interface AwaitJson {
  nodeName: string;
  values: string[];
}

export interface WorkflowInputParams {
  inputParameters: {
    key: string;
    displayName?: string;
    type?: string;
    description?: string;
    isOptional?: string;
  }[];
}

export interface Operators {
  operators: OperatorData[];
}

export interface OperatorData {
  symbol: string;
  name: string;
  type: string;
  label: string;
  isVariadic?: boolean;
  description?: string;
  arguments?: {
    type: string;
    variadic: boolean;
    description: string;
  }[];
  supportedDataTypes: string[];
}

export interface ServiceDescriptions {
  [key: string]: string;
}

export interface Templates {
  templateId: string;
  name: string;
  workflowObject: Workflow;
  tags?: string[];
  countryList?: string[];
  capabilities?: string[];
}

export interface ContentfulService {
  fields: {
    capabilityIDs: string[];
    capabilityDescription: string;
    servicesId: string;
    capabilitySupportedCountry: string[];
  };
}

export interface ContentfulBodyParam {
  fields: {
    bodyParamDescription: string;
    bodyParamName: string;
    bodyParamType: string;
  };
}

export interface ContentfulConfigureService {
  fields: {
    selectCapability: ContentfulService;
    selectBodyParams: ContentfulBodyParam[];
    title: string;
    id: string;
  };
}

export interface ContentfulFunctionArgument {
  fields: {
    id: string;
    argumentType: string;
    argumentDescription: string;
  };
}

export interface ContentfulCustomFunction {
  fields: {
    id: string;
    customFunctionName: string;
    description: string;
    selectArguments: ContentfulFunctionArgument[];
  };
}

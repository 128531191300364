import React, { useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Text, Box, Flex, Button } from '@chakra-ui/react';
import { useTable, Column, useSortBy, usePagination } from 'react-table';
import { CaretRight, Check } from 'phosphor-react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { EnvToggleAtom } from '@/states/envToggleAtom';
import { useRaiseServiceAccess } from '../hooks/useRaiseServiceAccess';
import { ApiData } from '../hooks/useApiCatalogue';

type CountryCode = 'USA' | 'UK' | 'IND' | 'PHI' | 'IDN' | 'GLOBAL';

const USFlag = (): JSX.Element => (
  <svg viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="50" fill="#fff" />
    <g clipPath="url(#circle)">
      {/* Stripes */}
      {[...Array(13)].map((_, i) => (
        <rect
          // eslint-disable-next-line react/no-array-index-key
          key={`stripe-${i}`}
          x="0"
          y={i * 7.69}
          width="100"
          height="7.69"
          fill={i % 2 === 0 ? '#B22234' : '#FFFFFF'}
        />
      ))}
      {/* Blue canton */}
      <rect x="0" y="0" width="40" height="46.15" fill="#3C3B6E" />
      {/* Stars */}
      {[...Array(9)].map((index, row) =>
        [...Array(row % 2 === 0 ? 6 : 5)].map((i, col) => (
          <circle
            // eslint-disable-next-line react/no-array-index-key
            key={`star-${row}-${col}`}
            cx={col * 6.5 + (row % 2 === 0 ? 4 : 7)}
            cy={row * 4.6 + 4}
            r="1.5"
            fill="#FFFFFF"
          />
        )),
      )}
    </g>
    <clipPath id="circle">
      <circle cx="50" cy="50" r="50" />
    </clipPath>
  </svg>
);

const IndiaFlag = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_1515_4151)">
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.9998 0C9.6564 0 4.1754 3.69156 1.5874 9.0435H30.4121C27.8242 3.69156 22.3431 0 15.9998 0Z"
        fill="#FF9811"
      />
      <path
        d="M15.9998 32C22.3431 32 27.8242 28.3085 30.4122 22.9565H1.5874C4.1754 28.3085 9.6564 32 15.9998 32Z"
        fill="#6DA544"
      />
      <path
        d="M15.9998 21.5649C19.0733 21.5649 21.5649 19.0733 21.5649 15.9998C21.5649 12.9262 19.0733 10.4346 15.9998 10.4346C12.9262 10.4346 10.4346 12.9262 10.4346 15.9998C10.4346 19.0733 12.9262 21.5649 15.9998 21.5649Z"
        fill="#0052B4"
      />
      <path
        d="M16.0002 19.4785C17.9212 19.4785 19.4785 17.9212 19.4785 16.0002C19.4785 14.0792 17.9212 12.522 16.0002 12.522C14.0792 12.522 12.522 14.0792 12.522 16.0002C12.522 17.9212 14.0792 19.4785 16.0002 19.4785Z"
        fill="#F0F0F0"
      />
      <path
        d="M15.9998 11.708L17.0728 14.1416L19.7168 13.8541L18.1458 16.0001L19.7168 18.1462L17.0728 17.8586L15.9998 20.2923L14.9267 17.8586L12.2827 18.1461L13.8537 16.0001L12.2827 13.8541L14.9267 14.1416L15.9998 11.708Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1515_4151">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UKFlag = (): JSX.Element => (
  <svg viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="48" fill="#012169" />
    <clipPath id="circleUK">
      <circle cx="50" cy="50" r="48" />
    </clipPath>
    <g clipPath="url(#circleUK)">
      <path d="M0,0 L100,100 M100,0 L0,100" stroke="#FFFFFF" strokeWidth="15" />
      <path d="M50,0 L50,100 M0,50 L100,50" stroke="#FFFFFF" strokeWidth="25" />
      <path d="M50,0 L50,100 M0,50 L100,50" stroke="#C8102E" strokeWidth="15" />
      <path d="M0,0 L100,100 M100,0 L0,100" stroke="#C8102E" strokeWidth="10" />
    </g>
  </svg>
);

const PHFlag = (): JSX.Element => (
  <svg viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="48" fill="#fff" />
    <g clipPath="url(#circlePH)">
      <rect x="0" y="0" width="100" height="50" fill="#0038A8" />
      <rect x="0" y="50" width="100" height="50" fill="#CE1126" />
      <polygon points="0,0 50,50 0,100" fill="#FCD116" />
    </g>
    <clipPath id="circlePH">
      <circle cx="50" cy="50" r="48" />
    </clipPath>
  </svg>
);

const GlobalFlag = (): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="#5A75F0" stroke="white" strokeWidth="2" />
    <path
      d="M16 25C20.9706 25 25 20.9706 25 16C25 11.0294 20.9706 7 16 7C11.0294 7 7 11.0294 7 16C7 20.9706 11.0294 25 16 25Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7 16H25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16 24.756C18.071 24.756 19.75 20.8356 19.75 16C19.75 11.1644 18.071 7.244 16 7.244C13.929 7.244 12.25 11.1644 12.25 16C12.25 20.8356 13.929 24.756 16 24.756Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IDNFlag = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" fill="#727C8D" stroke="white" strokeWidth="2" />
  </svg>
);

const FLAGS: Record<CountryCode, React.ReactNode> = {
  USA: <USFlag />,
  UK: <UKFlag />,
  IND: <IndiaFlag />,
  PHI: <PHFlag />,
  IDN: <IDNFlag />,
  GLOBAL: <GlobalFlag />,
};

const CountryFlag = ({ countries }: { countries: string[] }): JSX.Element => {
  if (!countries.length || countries.length > 4 || countries.includes('GLOBAL')) return <GlobalFlag />;

  const reversedCountries = [...countries].reverse();

  return (
    <Box position="relative" display="inline-block" w="36px">
      {reversedCountries.map((country, index) => {
        const totalFlags = reversedCountries.length;
        const position = totalFlags - 1 - index;

        return (
          <Box
            key={country}
            position={index === 0 ? 'relative' : 'absolute'}
            right={`${position * 20}px`}
            top="0"
            borderRadius="full"
            overflow="hidden"
            boxShadow="md"
            border="2px solid"
            borderColor="gray.100"
          >
            {FLAGS[country as CountryCode]}
          </Box>
        );
      })}
    </Box>
  );
};

const ApiTable = ({
  apiData,
  selectedCategory,
  selectedSearchResult,
}: {
  apiData: ApiData[];
  selectedCategory: string;
  selectedSearchResult?: string;
}): JSX.Element => {
  const tableRef = useRef<HTMLTableSectionElement>(null);
  const history = useHistory();
  const toggledEnv = useRecoilValue(EnvToggleAtom);
  const isProd = toggledEnv === 'live';
  const [pendingRequests, setPendingRequests] = React.useState<Set<string>>(new Set());
  const { mutateAsync: raiseServiceApprovalRequest } = useRaiseServiceAccess();

  const columns = React.useMemo<Column<ApiData>[]>(
    () => [
      {
        Header: 'API SOLUTIONS',
        accessor: 'name',
        Cell: ({ value }) => (
          <Text
            fontSize="14px"
            fontWeight="400"
            color="gray.600"
            fontVariant="lining-nums tabular-nums"
            lineHeight="normal"
            whiteSpace="normal"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {value}
          </Text>
        ),
      },
      {
        Header: 'COUNTRY',
        accessor: 'country',
        Cell: ({ value }) => <CountryFlag countries={value} />,
      },
      {
        Header: 'SUBSCRIPTION',
        accessor: 'subscription',
        Cell: ({ row, value }) => {
          const isActive = value === 'Active';
          const isPending = value === 'Pending' || pendingRequests.has(row.original.endpoint);

          if (isProd) {
            return (
              <Flex align="center" gap="2">
                <Box
                  width="5"
                  height="5"
                  borderRadius="full"
                  bg={isActive ? 'green.500' : 'gray.300'}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr="1"
                  boxShadow="sm"
                  fontWeight="light"
                >
                  {isActive && <Check weight="bold" color="white" size={16} />}
                </Box>
                <Text color={isActive ? 'green.500' : 'gray.500'} fontSize="xs" fontWeight="300">
                  {isActive ? 'Active' : 'Inactive'}
                </Text>
              </Flex>
            );
          }

          return (
            <Flex align="center" gap="2">
              {isActive && (
                <Box
                  width="5"
                  height="5"
                  borderRadius="full"
                  bg="green.500"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mr="1"
                  boxShadow="sm"
                  fontWeight="light"
                >
                  <Check weight="bold" color="white" size={16} />
                </Box>
              )}
              {isActive ? (
                <Text color="green.500" fontSize="xs" fontWeight="300">
                  Active
                </Text>
              ) : (
                <Flex direction="column" alignItems="center" justifyContent="center" gridGap="1.5">
                  <Button
                    variant="outline"
                    h="6"
                    color={isPending ? 'gray.600' : 'blue.500'}
                    bg={isPending ? 'white.50' : 'blue.100'}
                    fontSize="xs"
                    fontWeight="light"
                    borderColor="blueGray.100"
                    onClick={event => {
                      event.stopPropagation();
                      raiseServiceApprovalRequest(row.original.endpoint);
                      setPendingRequests(prev => new Set(prev).add(row.original.endpoint));
                    }}
                    isDisabled={isPending}
                  >
                    {isPending ? 'Request Sent' : 'Request'}
                  </Button>
                  {isPending && (
                    <Text fontSize="xs" fontWeight="light" color="gray.600">
                      Waiting for approval
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
          );
        },
      },
      //   {
      //     Header: () => (
      //       <>
      //         PRICE PER TRANSACTION
      //         <Text as="span" display="block" mt="1">
      //           (No. of credits consumed)
      //         </Text>
      //       </>
      //     ),
      //     accessor: 'credits',
      //     Cell: ({ value }) => {
      //       return (
      //         <Text
      //           fontSize="14px"
      //           fontWeight="400"
      //           color="gray.600"
      //           fontVariant="lining-nums tabular-nums"
      //           lineHeight="normal"
      //         >
      //           1 credit
      //         </Text>
      //       );
      //     },
      //   },
      {
        Header: '',
        id: 'arrow',
        Cell: () => <CaretRight size={20} color="gray" />,
      },
    ],
    [isProd, pendingRequests, raiseServiceApprovalRequest],
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, rows } = useTable(
    {
      columns,
      data: apiData,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    usePagination,
  );

  const scrollToMatch = (): void => {
    if (!selectedSearchResult) return;

    const matchedRowIndex = rows.findIndex(row => row.values.name.toLowerCase() === selectedSearchResult.toLowerCase());

    if (matchedRowIndex !== -1) {
      if (!tableRef.current) return;
      const tableRows = tableRef.current?.getElementsByTagName('tr');
      const matchedRow = tableRows[matchedRowIndex];

      if (matchedRow) {
        matchedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  useEffect(() => {
    if (selectedSearchResult) {
      scrollToMatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchResult]);

  return (
    <Flex direction="column" overflow="auto" h="full" borderTopRadius="lg" position="relative">
      <Table {...getTableProps()} fontSize="sm" fontWeight="300" color="gray.700" maxH="100%">
        <Thead position="sticky" top={0} bgColor="white" boxShadow="0 1px 0 0 #EFEFEF, inset 0 1px 0 0 #EFEFEF">
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.id} color="gray.500" p="0">
                  <Flex as={Box} alignItems="center" px={5} py={4} color="gray.500" w="100%" justifyContent="start">
                    <Text
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      maxW={200}
                      textTransform="uppercase"
                      fontSize="xs"
                      fontWeight="light"
                      lineHeight="13px"
                      color="gray.500"
                    >
                      {column.render('Header')}
                    </Text>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} ref={tableRef} h="100%" position="relative" overflowY="scroll">
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                key={row.id}
                _hover={{ bg: 'lavender.100' }}
                height="56px"
                borderColor="blue.100"
                borderTopWidth="1px"
                borderBottomWidth="1px"
                bgColor={selectedSearchResult === row.original.name ? 'blue.100' : 'white'}
                cursor="pointer"
                onClick={e => {
                  history.push(`/docs/try-out/${selectedCategory}/${row.original.apiName}/`);
                  e.stopPropagation();
                }}
              >
                {row.cells.map(cell => (
                  <Td
                    {...cell.getCellProps()}
                    key={cell.column.id}
                    fontSize="sm"
                    fontWeight="light"
                    color="gray.700"
                    maxW="290px"
                    isTruncated
                    verticalAlign="center"
                  >
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default ApiTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

const useImageUpload = (options?: UseMutationOptions<any, AxiosError, any>): UseMutationResult<any, AxiosError, any> =>
  useMutation(imageUpload, options);

const imageUpload = async ({ documentId, front }: any): Promise<any> => {
  const data = await bureauPlatformApi.post<any>(`/documents/${documentId}/object`, front, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};

export default useImageUpload;

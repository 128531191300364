import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';
import { AxiosError } from 'axios';
import { InputTypes } from '../components/ApiTryoutWrapper';

interface PayloadType {
  [key: string]: InputTypes;
}

interface ServiceCallProps {
  endpoint: string;
  data: PayloadType;
}

type ServiceCallResponse = Record<string, unknown>;

export const useTryoutService = (
  options?: UseMutationOptions<ServiceCallResponse, AxiosError, ServiceCallProps>,
): UseMutationResult<ServiceCallResponse, AxiosError, ServiceCallProps> => {
  return useMutation((variables: ServiceCallProps) => tryoutService(variables.endpoint, variables.data), options);
};

const tryoutService = async (endpoint: string, data: PayloadType): Promise<ServiceCallResponse> => {
  const res = await bureauPlatformApi.post<ServiceCallResponse>(endpoint, data);
  return res.data;
};

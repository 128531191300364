import React, { useState, useEffect } from 'react';
import { Box, Flex, VStack, Text } from '@chakra-ui/react';
import FactsLoader from '@/components/loader/Loader';
import Dropdown from '@/components/dropdown/Dropdown';
import { OptionType } from '@bureau/components';
import { useHistory, useLocation } from 'react-router-dom';
import Select, { StylesConfig } from 'react-select';
import { colors } from '@/utils/colors';
import { Search2Icon } from '@chakra-ui/icons';
import ApiTable from './ApiTable';
import { ApiData, useApiCatalogue } from '../hooks/useApiCatalogue';

const statusOptions: OptionType<string>[] = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Pending', value: 'Pending' },
  // { label: 'Requested', value: 'Requested' },
];

// Define country options
const countryOptions: OptionType<string>[] = [
  { label: 'India', value: 'IND' },
  { label: 'Indonesia', value: 'IDN' },
  { label: 'USA', value: 'USA' },
  { label: 'UK', value: 'UK' },
  { label: 'Philippines', value: 'PHL' },
  { label: 'GLOBAL', value: 'GLOBAL' },
];

type GroupType = { label: string; options: OptionType[] };
type IsMulti = false;

const reactSelectStyles: StylesConfig<OptionType, IsMulti, GroupType> = {
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
  control: provided => ({
    ...provided,
    borderColor: colors.gray[200],
    borderRadius: '6px',
    minWidth: 500,
    width: '100%',
    fontSize: '14px',
    fontWeight: 300,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: '8px',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#98A3B8',
    fontSize: '14px',
    fontWeight: 300,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
};

const Catalogue = (): JSX.Element => {
  const { leftNavItems, selectedCategory, setSelectedCategory, apiData, isLoading, allAPIsData } = useApiCatalogue();
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(statusOptions.map(option => option.value));
  const [selectedCountries, setSelectedCountries] = useState<string[]>(countryOptions.map(option => option.value));
  const [searchQuery, setSearchQuery] = useState<string>('');

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    if (category && leftNavItems.includes(category)) {
      setSelectedCategory(category);
    }
  }, [location.search, leftNavItems, setSelectedCategory]);

  const allAPIsList = Object.entries(allAPIsData).flatMap(([category, innerObj]) =>
    Object.values(innerObj)
      .filter(api => api.title)
      .map(item => ({
        value: item.title,
        label: item.title,
        category,
      })),
  );

  const handleCategorySelect = (category: string): void => {
    setSelectedCategory(category);
    setSearchQuery('');
    const params = new URLSearchParams(location.search);
    params.set('category', category);
    history.push({ search: params.toString() });
  };

  if (isLoading) {
    return (
      <Flex direction="column" height="100%">
        <FactsLoader />
      </Flex>
    );
  }

  const filterApiData = (): ApiData[] => {
    const filterByStatus = apiData.filter(api =>
      selectedStatuses.length ? selectedStatuses.includes(api.subscription) : true,
    );
    const filterByCountry = filterByStatus.filter(api =>
      selectedCountries.length ? api.country.some(country => selectedCountries.includes(country)) : true,
    );
    return filterByCountry;
  };

  const filteredApiDataByStatusAndCountry = filterApiData();

  return (
    <Flex h="full" direction="column" overflow="hidden">
      <Flex ml="254px" mb="6" mt="1" flexShrink={0} justifyContent="space-between" alignItems="center">
        <Select
          isSearchable
          isClearable
          menuShouldScrollIntoView
          escapeClearsValue
          options={allAPIsList}
          className="basic-single"
          classNamePrefix="select"
          placeholder={
            <Flex gridGap="1" alignItems="center">
              <Search2Icon /> Search
            </Flex>
          }
          onChange={selectedOptions => {
            if (selectedOptions) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              const category = selectedOptions?.category;
              handleCategorySelect(category);
              setSearchQuery(selectedOptions?.value);
            } else {
              setSearchQuery('');
            }
          }}
          value={searchQuery ? allAPIsList.find(apiItem => apiItem.label === searchQuery) : null}
          styles={reactSelectStyles}
        />
        <Flex direction="row" gap="4">
          <Flex alignItems="center">
            <Text pl={8} fontWeight={300} color="gray.600" fontSize="xs" id="transactions-outcome">
              Subscription:
            </Text>
            <Dropdown
              value={selectedStatuses}
              onChange={(field, event) => {
                const status = field as OptionType<string>[];
                setSelectedStatuses(status.map(option => option.value));
              }}
              options={statusOptions}
              isMulti
              controlWidth="50px"
              placeholder="All"
              variant="ghost"
              style={{ border: 'none' }}
              menuHeading="Select status"
            />
          </Flex>

          <Flex alignItems="center">
            <Text pl={8} fontWeight={300} color="gray.600" fontSize="xs" id="country-filter">
              Country:
            </Text>
            <Dropdown
              value={selectedCountries}
              onChange={(field, event) => {
                const countries = field as OptionType<string>[];
                setSelectedCountries(countries.map(option => option.value));
              }}
              options={countryOptions}
              isMulti
              controlWidth="50px"
              placeholder="All"
              variant="ghost"
              style={{ border: 'none' }}
              menuHeading="Select country"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="row" flex="1" overflow="hidden">
        <Box w="230px" mr="6" overflowY="auto" flexShrink={0}>
          <VStack align="stretch" spacing="0" gap="6">
            {leftNavItems.map(item => (
              <LeftNavItem
                key={`nav-${item}`}
                title={item}
                isSelected={item === selectedCategory}
                onClick={() => handleCategorySelect(item)}
              />
            ))}
          </VStack>
        </Box>
        <Box flex="1" overflowY="scroll">
          <ApiTable
            selectedSearchResult={searchQuery}
            apiData={filteredApiDataByStatusAndCountry}
            selectedCategory={String(selectedCategory)}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

const LeftNavItem = ({
  title,
  isSelected,
  onClick,
}: {
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
}): JSX.Element => (
  <Text
    cursor="pointer"
    color={isSelected ? 'blue.800' : 'gray.600'}
    fontWeight={isSelected ? '600' : '400'}
    py="3"
    px="4"
    w="full"
    _hover={{
      bg: 'gray.50',
      color: 'blue.500',
    }}
    transition="all 0.2s"
    onClick={onClick}
    fontSize="14px"
    fontVariant="lining-nums tabular-nums"
    lineHeight="normal"
  >
    {title}
  </Text>
);

export default Catalogue;

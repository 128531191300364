import React from 'react';
import { Header, Heading } from '@/layout/Header';
import { Box, Flex } from '@chakra-ui/react';
import { BulkQueryBannerHeight } from '@/constants/constants';
import PlatformTooltip from '@/components/tooltip/PlatformTooltip';
import { Info } from 'phosphor-react';
import Catalogue from './components/Catalogue';

const DocsWrapper = (): React.ReactElement => {
  return (
    <Box w="full" h="full">
      <Header>
        <Heading headingProps={{ gridGap: '2' }}>
          <Heading.Title>API Explorer</Heading.Title>
          <Heading.SubHeader>
            <PlatformTooltip description="Discover and test Bureau’s API solutions designed to help you verify and onboard users seamlessly!">
              <Info size={16} />
            </PlatformTooltip>
          </Heading.SubHeader>
        </Heading>
      </Header>
      <Flex direction="column" gridGap="5" w="full" p="6" h={`calc(100% - ${BulkQueryBannerHeight}px)`}>
        <Catalogue />
      </Flex>
    </Box>
  );
};

export default DocsWrapper;

import {
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Buildings } from 'phosphor-react';
import { useAuthDetails } from '@/queries/useAuth';
import { useDocumentation } from '@/queries/UseDocumentation';
import { useAuthKeyCreation } from '@/screens/admin/queries/useAdminKey';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { setupAuth, setupOrganizationToken } from '@/utils/api';
import { useCreateOrganization } from '../queries/useCreateOrganization';
import { CreateOrgPropTypes } from '../types';

function CreateOrganization({ handleNextPage, selectionData, setSelectionData }: CreateOrgPropTypes): ReactElement {
  const [isChecked, setIsChecked] = useState(false);
  const OrgCreateSchema = Yup.object().shape({
    displayName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      displayName: selectionData.displayName ?? '',
    },
    validationSchema: OrgCreateSchema,
    onSubmit: orgName => {
      mutateAsync({
        displayName: orgName.displayName,
        industry: 'fintech',
        geography: 'in',
      });
    },
  });

  const history = useHistory();
  const { refetch: refetchAuthDetails } = useAuthDetails();
  const { refetch: refetchDocsLink } = useDocumentation();
  const { mutateAsync: createAPICredentials } = useAuthKeyCreation({ retry: true });
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const { mutateAsync, isLoading: isCreateOrgLoading } = useCreateOrganization({
    onSuccess: (res: { organisation_id?: string }) => {
      if (res.organisation_id) {
        setupOrganizationToken(res.organisation_id);
        refetchToken();
      }
    },
    onError: error => {
      // TODO: Error message has to be updated
      toast({
        title: 'Unable to create the organization. Please contact support for assistance.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });
  const refetchToken = async (): Promise<void> => {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    setupAuth(token);
    refetchAuthDetails();
    createAPICredentials({ app_name: 'Bureau Docs' }).then(() => {
      refetchDocsLink();
    });
    history.push('/dashboard');
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
          <VStack spacing={4}>
            <Flex direction="column" align="center">
              <Text fontSize="3xl" fontWeight="bold" padding="5px">
                Create Organisation
              </Text>
              <Text fontSize="sm" padding="5px">
                Enter a name for organisation
              </Text>
            </Flex>
            <Flex marginTop={8} w="full">
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Buildings />
                </InputLeftElement>
                <Input
                  type="text"
                  name="displayName"
                  placeholder="My Organisation"
                  onChange={formik.handleChange}
                  value={formik.values.displayName}
                />
              </InputGroup>
            </Flex>
            <Flex direction="column" gridGap="4" alignItems="center">
              <Checkbox
                isChecked={isChecked}
                onChange={e => setIsChecked(e.target.checked)}
                size="sm"
                colorScheme="blue"
              >
                <Text fontSize="sm">
                  I agree to{' '}
                  <Link
                    href="https://www.bureau.id/privacy-policy"
                    color="blue.500"
                    isExternal
                    textDecoration="underline"
                  >
                    Privacy Policy
                  </Link>
                  {' & '}
                  <Link
                    href="https://www.bureau.id/terms-and-conditions"
                    color="blue.500"
                    isExternal
                    textDecoration="underline"
                  >
                    Terms and Conditions
                  </Link>
                </Text>
              </Checkbox>
            </Flex>
            <Flex marginTop={4}>
              <Button
                display="flex"
                fontSize="sm"
                borderRadius="lg"
                fontWeight="light"
                height="44px"
                _hover={{ opacity: '0.85' }}
                mr="4"
                mt="4"
                type="submit"
                isDisabled={formik.isSubmitting || !formik.isValid || !isChecked || isCreateOrgLoading}
                isLoading={isCreateOrgLoading}
                colorScheme="blue"
              >
                Proceed
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </form>
    </>
  );
}

export default CreateOrganization;

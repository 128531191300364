import { useQuery, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { bureauPlatformApi } from '@/utils/api';
import { GetWorkflowResponse } from '../types';

export const useGetWorkflow = (id: string): UseQueryResult<GetWorkflowResponse, AxiosError> => {
  return useQuery('workflowById', getDetails(id), {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!id,
    select: data => {
      if (!data.workflowObject.columns) return data;
      return {
        ...data,
        workflowObject: {
          ...data.workflowObject,
          columns: data.workflowObject.columns.map(column => ({
            ...column,
            nodes: column.nodes.map(node => ({
              ...node,
              platformDisplayName: node.platformDisplayName || node.name,
            })),
          })),
        },
      };
    },
  });
};

const getDetails = (id: string) => {
  return async () => {
    const res = await bureauPlatformApi.get<GetWorkflowResponse>(`/workflows/${id}`);
    return res.data;
  };
};

export default useGetWorkflow;

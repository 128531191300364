import React, { ReactElement, useMemo, useState } from 'react';
import { Button, Flex, Heading, Text, Grid, Image, Box, ButtonProps, useToast, Checkbox, Link } from '@chakra-ui/react';

import { Footer } from '@/screens/user-onboarding/components/Footer';
import Header from '@/screens/user-onboarding/components/Header';
import { colors } from '@/utils/colors';
import { setupAuth, setupOrganizationToken } from '@/utils/api';
import useDecodedToken from '@/hooks/useDecodedToken';
import ComingSoonTag from '@/components/coming-soon/ComingSoonTag';
import { useHistory } from 'react-router-dom';
import { useAuthDetails } from '@/queries/useAuth';
import { useDocumentation } from '@/queries/UseDocumentation';
import { useAuth0 } from '@auth0/auth0-react';
import { useCreateOrganization } from './queries/useCreateOrganization';
import { CapabilityFilterItem, MessagePagePropTypes, OnboardingInputData, Pages, QuestionPagePropTypes } from './types';
import { getIndustryIcon, OTHERS_ICON } from './constants';
import { useCapabilityFilters } from './queries/useCapabilityFilters';
import CreateOrganization from './organisation/CreateOrganization';
import { useAuthKeyCreation } from '../admin/queries/useAdminKey';

const getOptionButtonProps = (option: CapabilityFilterItem): ButtonProps => ({
  variant: 'outline',
  cursor: option.comingSoon ? 'default !important' : 'pointer',
  textTransform: 'capitalize',
  gridGap: '1',
  borderRadius: 'lg',
  borderColor: option.comingSoon ? 'gray.500' : 'gray.900',
  fontSize: 'sm',
  fontWeight: '400',
  w: '336px',
  h: '91px',
  px: '6',
  py: '3',
  justifyContent: 'space-between',
  _hover: { bg: option.comingSoon ? 'white' : 'blue.50' },
  _focus: { boxShadow: 'none' },
  isDisabled: option.comingSoon,
});

function UserOnboarding(): ReactElement {
  const { data: capabilityFilters } = useCapabilityFilters();
  const history = useHistory();
  const { refetch: refetchAuthDetails } = useAuthDetails();
  const { refetch: refetchDocsLink } = useDocumentation();
  const { mutateAsync: createAPICredentials } = useAuthKeyCreation({ retry: true });
  const toast = useToast();
  const { getAccessTokenSilently } = useAuth0();
  const { mutateAsync, isLoading: isCreateOrgLoading } = useCreateOrganization({
    onSuccess: (res: { organisation_id?: string }) => {
      if (res.organisation_id) {
        setupOrganizationToken(res.organisation_id);
        refetchToken();
      }
    },
    onError: error => {
      // TODO: Error message has to be updated
      toast({
        title: 'Unable to create the organization. Please contact support for assistance.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    },
  });
  const [page, setPage] = useState<Pages>(Pages.CreateOrganisation);
  const [selectionData, setSelectionData] = useState<OnboardingInputData>({
    displayName: '',
    industry: '',
    geography: '',
  });

  const refetchToken = async (): Promise<void> => {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    setupAuth(token);
    refetchAuthDetails();
    createAPICredentials({ app_name: 'Bureau Docs' }).then(() => {
      refetchDocsLink();
    });
    history.push('/dashboard');
  };

  const industryList = useMemo(
    () =>
      capabilityFilters?.industries.map(industry => ({
        ...industry,
        icon: getIndustryIcon.get(industry.value) ?? OTHERS_ICON,
      })) ?? [],
    [capabilityFilters],
  );

  const countryList = useMemo(
    () =>
      capabilityFilters?.geographies.map(country => ({
        ...country,
        icon: <Image src={`/assets/flags/${country.value}.svg`} w="10" h="30" />,
      })) ?? [],
    [capabilityFilters],
  );

  const createOrganisation = (): void => {
    mutateAsync(selectionData);
  };

  const handleNextPage = (): void => {
    if (page < Pages.WelcomeMessage) {
      setPage((pageNum: number) => pageNum + 1);
    }
  };

  const handlePrevPage = (): void => {
    if (page > Pages.CreateOrganisation) {
      setPage((pageNum: number) => pageNum - 1);
    }
  };

  const renderPageContent = (): ReactElement => {
    switch (page) {
      case Pages.CreateOrganisation:
        return (
          <CreateOrganization
            handleNextPage={handleNextPage}
            selectionData={selectionData}
            setSelectionData={setSelectionData}
          />
        );
      case Pages.Industries:
        return (
          <QuestionPage
            page={page}
            selectionData={selectionData}
            setSelectionData={setSelectionData}
            options={industryList}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        );
      case Pages.Geographies:
        return (
          <QuestionPage
            page={page}
            selectionData={selectionData}
            setSelectionData={setSelectionData}
            options={countryList}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        );
      case Pages.WelcomeMessage:
        return (
          <MessagePage
            createOrganisationHandler={createOrganisation}
            isLoading={isCreateOrgLoading}
            handlePrevPage={handlePrevPage}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Header />
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center" overflow="auto" maxH="100vh" bg="white">
        {renderPageContent()}
      </Flex>
      <Footer />
    </>
  );
}

function QuestionPage({
  page,
  selectionData,
  setSelectionData,
  options,
  handleNextPage,
  handlePrevPage,
}: QuestionPagePropTypes): ReactElement {
  const pageType = (page === Pages.Industries ? 'industry' : 'geography') as keyof OnboardingInputData;

  const LeftIcon = ({ option }: { option: CapabilityFilterItem }): ReactElement => (
    <Box
      filter={option.comingSoon ? 'grayscale(1)' : 'none'}
      webkitFilter={option.comingSoon ? 'grayscale(1)' : 'none'}
    >
      {option.icon}
    </Box>
  );

  return (
    <Flex direction="column" w="720px" textAlign="center" gridGap="12">
      <Flex direction="column" gridGap="4">
        <Heading w="87%" margin="auto" fontWeight="500" fontSize="32px" lineHeight="normal">
          Please share a bit about yourself to help us tailor your experience
        </Heading>
        <Text color="gray.550" lineHeight="130%">
          {pageType === 'industry'
            ? 'Please select the industry to which your company belongs.'
            : 'Please select the country in which your company operates.'}
        </Text>
      </Flex>
      <Grid gridTemplateColumns="1fr 1fr" gridRowGap="6" gridColumnGap="12">
        {options.map(option => (
          <Button
            {...getOptionButtonProps(option)}
            key={option.value}
            leftIcon={<LeftIcon option={option} />}
            rightIcon={option.comingSoon ? <ComingSoonTag size="lg" /> : <></>}
            bg={option.value === selectionData[pageType] ? 'white.100' : 'white'}
            onClick={() => {
              setSelectionData((data: OnboardingInputData) => ({
                ...data,
                [pageType]: data[pageType] === option.value ? '' : option.value,
              }));
            }}
          >
            {/* button text is put inside Text component to fix alignment of text, icon and tag in the button */}
            <Text sx={{ textWrap: 'wrap' }} textAlign="left" flex="auto">
              {option.displayText}
            </Text>
          </Button>
        ))}
      </Grid>
      <Flex direction="column" gridGap="4" alignItems="center">
        <Text
          px="4"
          py="2"
          bg="blue.100"
          color="blue.500"
          borderRadius="base"
          fontSize="sm"
          fontWeight="300"
          lineHeight="130%"
        >
          <b>NOTE - </b>
          You can edit your {pageType} in the organisation settings
        </Text>
        <Button
          colorScheme="blue"
          px="10"
          py="3"
          fontSize="sm"
          fontWeight="300"
          onClick={handleNextPage}
          isDisabled={!selectionData[pageType]}
        >
          Continue
        </Button>
        {page > Pages.CreateOrganisation && (
          <Button
            variant="link"
            fontSize="xs"
            fontWeight="400"
            lineHeight="normal"
            colorScheme="blue"
            onClick={handlePrevPage}
          >
            Go Back
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

function MessagePage({ createOrganisationHandler, isLoading, handlePrevPage }: MessagePagePropTypes): ReactElement {
  const { isLoading: isTokenLoading, userDetails } = useDecodedToken();
  const username: string = userDetails?.nickname;
  const [isChecked, setIsChecked] = useState(false);

  if (isTokenLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <Flex direction="column" w="688px" textAlign="center" gridGap="12">
        <Heading fontWeight="500" fontSize="32px" lineHeight="normal" textTransform="capitalize">
          Hey {username}!
        </Heading>
        <Image src="/assets/user-onboarding.svg" w="102" h="150" />

        <Flex direction="column" gridGap="5">
          <Text textAlign="left" fontSize="xl" lineHeight="150%" color="gray.900">
            Welcome to Bureau Platform!
          </Text>
          <Text lineHeight="150%" textAlign="left" color="gray.550">
            We are thrilled to have you as part of our community, and we look forward to witnessing your success as you
            explore the full potential of our platform.
          </Text>
          <Text lineHeight="150%" textAlign="left" color="gray.550">
            As you embark on this exciting journey, our team has put together a{' '}
            <a
              href="https://docs.bureau.id/"
              target="_blank"
              style={{ color: colors.purple[100] }}
              rel="noopener noreferrer"
            >
              helpful guide
            </a>{' '}
            to assist you in getting started. Feel free to ask any questions, and remember, our support team is
            available to assist you every step of the way.
          </Text>
        </Flex>
        <Flex direction="column" gridGap="4" alignItems="center">
          <Checkbox isChecked={isChecked} onChange={e => setIsChecked(e.target.checked)} size="sm" colorScheme="blue">
            <Text fontSize="sm">
              I agree to{' '}
              <Link href="https://www.bureau.id/privacy-policy" color="blue.500" isExternal textDecoration="underline">
                Privacy Policy
              </Link>
              {' & '}
              <Link
                href="https://www.bureau.id/terms-and-conditions"
                color="blue.500"
                isExternal
                textDecoration="underline"
              >
                Terms and Conditions
              </Link>
            </Text>
          </Checkbox>
          <Button
            colorScheme="blue"
            px="10"
            py="3"
            fontSize="sm"
            fontWeight="300"
            isLoading={isLoading}
            onClick={createOrganisationHandler}
            loadingText="Setting up Organization"
            isDisabled={!isChecked}
          >
            Get Started!
          </Button>
          <Button
            variant="link"
            fontSize="xs"
            fontWeight="400"
            lineHeight="normal"
            colorScheme="blue"
            onClick={handlePrevPage}
            isDisabled={isLoading}
          >
            Go Back
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

export default UserOnboarding;

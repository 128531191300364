import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';
import { AxiosError } from 'axios';

type ServiceApproval = {
  message: string;
};

export const useRaiseServiceAccess = (
  options?: UseMutationOptions<ServiceApproval, AxiosError, string>,
): UseMutationResult<ServiceApproval, AxiosError, string> => useMutation(raiseServiceAccessRequest, options);

const raiseServiceAccessRequest = async (serviceEndpoint: string): Promise<ServiceApproval> => {
  const res = await bureauPlatformApi.post<ServiceApproval>(`/v1/auth/raise_service_approval_request`, {
    service_endpoint: serviceEndpoint,
  });
  return res.data;
};

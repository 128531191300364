import { useState, useEffect, useCallback } from 'react';

interface UseCountdownProps {
  initialSeconds: number;
  onComplete?: () => void;
}

interface UseCountdownReturn {
  countdown: number;
  isActive: boolean;
  startCountdown: () => void;
  stopCountdown: () => void;
}

export const useCountdown = ({ initialSeconds, onComplete }: UseCountdownProps): UseCountdownReturn => {
  const [countdown, setCountdown] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isActive && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            setIsActive(false);
            onComplete?.();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isActive, countdown, onComplete]);

  const startCountdown = useCallback(() => {
    setCountdown(initialSeconds);
    setIsActive(true);
  }, [initialSeconds]);

  const stopCountdown = useCallback(() => {
    setCountdown(0);
    setIsActive(false);
  }, []);

  return {
    countdown,
    isActive,
    startCountdown,
    stopCountdown,
  };
};

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import useCreateWorkflow from '@/screens/workflows/workflow-creation/queries/useCreateWorkflow';

interface UseImportWorkflowProps {
  onClose: () => void;
}

interface UseImportWorkflowReturn {
  selectedFile: File | null;
  isLoadingWorkflowsave: boolean;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => Promise<void>;
}

export const useImportWorkflow = ({ onClose }: UseImportWorkflowProps): UseImportWorkflowReturn => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const history = useHistory();
  const toast = useToast();

  const { mutateAsync, isLoading: isLoadingWorkflowsave } = useCreateWorkflow({
    onSuccess: data => {
      history.push(`/workflows/${data.workflowId}/edit`);
      onClose();
    },
    onError: error => {
      toast({
        title: 'Failed to save workflow',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/json') {
      setSelectedFile(file);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (selectedFile) {
      try {
        const fileContent = await selectedFile.text();
        const workflowData = JSON.parse(fileContent);
        if (!workflowData.workflowObject) {
          toast({
            title: 'Invalid workflow file',
            description: 'The workflow file is invalid',
            status: 'error',
            duration: 1000,
            isClosable: true,
          });
          return;
        }
        mutateAsync({
          name: workflowData.name || 'Workflow',
          workflowObject: workflowData.workflowObject,
          releaseType: 'v2',
          useCase: workflowData.useCase,
        });
      } catch (error) {
        // console.error('Error parsing workflow file:', error);
      }
    }
  };

  return {
    selectedFile,
    isLoadingWorkflowsave,
    handleFileChange,
    handleSubmit,
  };
};

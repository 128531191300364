import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Radio, RadioGroup, Button } from '@chakra-ui/react';
import { MdArrowDropDown } from 'react-icons/md';

interface StatusMenuProps {
  options: { label: string; value: string }[];
  selectedValue: string;
  onChange: (value: string) => void;
}

const RadioDropDown: React.FC<StatusMenuProps> = ({ options, selectedValue, onChange }) => {
  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<MdArrowDropDown />}>
        Status
      </MenuButton>
      <MenuList>
        <RadioGroup value={selectedValue} onChange={onChange} size="4">
          {options.map(option => (
            <MenuItem key={option.value}>
              <Radio value={option.value}>{option.label}</Radio>
            </MenuItem>
          ))}
        </RadioGroup>
      </MenuList>
    </Menu>
  );
};

export default RadioDropDown;

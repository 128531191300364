/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { bureauPlatformApi } from '@/utils/api';

export const useGetDocument = (
  options?: UseMutationOptions<any, AxiosError, any>,
): UseMutationResult<any, AxiosError, any> => useMutation(getDocument, options);

const getDocument = async ({ documentId }: any): Promise<any> => {
  const { request } = await bureauPlatformApi.get<any>(`/documents/${documentId}/object`, {
    maxRedirects: 0,
    validateStatus: null,
  });
  return request;
};

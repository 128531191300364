import React, { ReactElement } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ChildItems, ColumnV2, ParentMenuItem, ResponseInputsV2 } from './types';
import InputDropdown from './InputDropdown';
import { SelectedNodeAtom } from './states/selectedNodeAtom';

export interface ServiceConfigPropsTypes {
  currentElement: ResponseInputsV2;
  parentItems: ColumnV2[];
  index: number;
  userInputs: { parentMenuItems: ParentMenuItem[]; childItems: ChildItems };
}

function ServiceConfiguration({ currentElement, parentItems, userInputs }: ServiceConfigPropsTypes): ReactElement {
  const selectedNode = useRecoilValue(SelectedNodeAtom);
  const setSelectedNode = useSetRecoilState(SelectedNodeAtom);

  function handleChange(
    key: string,
    value: ParentMenuItem,
    output: string,
    indexes?: {
      columnIndex: number;
      rowIndex: number;
      elementIndex: number;
    },
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clonedNode = JSON.parse(JSON.stringify(selectedNode.columns)) as any;
    const locationType = output.split('.')[2] || '';
    const locationKind = output.split('.')[0] || '';
    clonedNode[indexes?.columnIndex || 0].nodes[indexes?.rowIndex || 0].inputs[
      indexes?.elementIndex || 0
    ].location = output;
    clonedNode[indexes?.columnIndex || 0].nodes[indexes?.rowIndex || 0].inputs[
      indexes?.elementIndex || 0
    ].locationType = locationType;
    clonedNode[indexes?.columnIndex || 0].nodes[indexes?.rowIndex || 0].inputs[
      indexes?.elementIndex || 0
    ].locationKind = locationKind;

    setSelectedNode(prevNode => ({ ...prevNode, columns: clonedNode }));
  }

  const parentMenuItems = parentItems
    .map(column => {
      return {
        ...column,
        nodes: column.nodes.map(node => ({ ...node, capabilityName: column.metadata.capabilityName })),
      };
    })
    .flatMap(item => item.nodes)
    .filter(nodes => nodes.kind !== 'AWAIT')
    .filter(node => node.kind !== 'tag')
    .map(node => ({
      id: node.id,
      header: node.name,
      subHeader: node.capabilityName,
      platformDisplayName: node.platformDisplayName,
    })) as ParentMenuItem[];

  const childMenuItems = parentItems
    .flatMap(item => item.nodes)
    .filter(nodes => nodes.kind !== 'AWAIT')
    .filter(node => node.kind !== 'tag')
    .reduce((acc, node) => {
      const nodeOutputs = node.outputs?.map(output => ({ id: output.key || '', header: output.displayName || '' }));
      return { ...acc, [node.id]: nodeOutputs };
    }, {});

  const flattenedNode = selectedNode.columns
    .flatMap(column => column.nodes)
    .filter(nodes => nodes.kind !== 'AWAIT')
    .filter(node => node.kind !== 'tag');

  const parentServiceMenuItems = flattenedNode.map(
    node =>
      ({
        id: node.id,
        header: node.name,
        platformDisplayName: node.platformDisplayName || node.name,
      } as ParentMenuItem),
  );

  const childServiceNodeItems = flattenedNode.reduce((acc, node) => {
    const nodeOutputs = node.outputs?.map(output => ({ id: output.key || '', header: output.displayName || '' }));
    return { ...acc, [node.id]: nodeOutputs };
  }, {}) as ChildItems;

  function getSublevel(): string {
    const location = currentElement.location?.split('.') || [];
    if (location?.length && location[0] === 'input') {
      return `${location[2] || ''} (User Input) `;
    }

    const currentSourceId = location[1];
    const currentSource = [...parentMenuItems, ...parentServiceMenuItems].find(
      menuItem => menuItem.id === currentSourceId,
    );
    const currentSourceLabel = currentSource?.header || '';
    const currentSourceElementId = location[2];
    return currentSourceElementId ? `${currentSourceElementId} (${currentSourceLabel})}` : '';
  }

  return (
    <InputDropdown
      parentMenuItems={[...userInputs.parentMenuItems, ...parentMenuItems, ...parentServiceMenuItems]}
      childItems={{ ...childMenuItems, ...childServiceNodeItems, ...userInputs.childItems }}
      label={`${currentElement?.displayName} `}
      handleChange={handleChange}
      subLabel={getSublevel()}
      type="input"
      inputData={currentElement}
      styles={{ maxW: '350' }}
    />
  );
}

export default ServiceConfiguration;

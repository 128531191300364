/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosError } from 'axios';

export interface APIInputParameters {
  key: string;
  displayName: string;
  platformDescription: string;
  description: string;
  type: string;
  format?: string;
  isOptional: boolean;
  example: string;
  validationPattern?: string;
  allowedValues?: string[];
  isHiddenOnPlatform: boolean;
}

export type AsyncParams = {
  inputs: {
    key: string;
    type: string;
    showInDocs: boolean;
  }[];
  endPoint: string;
};

type ApiDetails = {
  title: string;
  description: string;
  method: string;
  path: string;
  countries: string[];
  status: string;
  ref: string;
  credits_required: number;
  input_fields: APIInputParameters[];
  isAsync: boolean;
  asyncParams?: AsyncParams;
  sample_responses: {
    code: number;
    type: string;
    label: string;
    description: string;
    color: string;
    schema: {
      sample: Record<string, any>;
      properties: Record<string, any>;
    };
  }[];
};

export type ApiCatalogueResponse = Record<string, Record<string, ApiDetails>>;

interface ApiCatalogueError {
  status: 'error';
  message: string;
}

export const useFetchApiCatalogue = (): UseQueryResult<ApiCatalogueResponse, AxiosError<ApiCatalogueError>> => {
  return useQuery('apiCatalogue', fetchApiCatalogue, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

const fetchApiCatalogue = async (): Promise<ApiCatalogueResponse> => {
  const response = await axios.get<ApiCatalogueResponse>(
    'https://d2qzyri2w07t1g.cloudfront.net/v1_api_documentation.json',
  );
  return response.data;
};

import React, { ChangeEvent, ReactElement, RefObject, useRef, useState } from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Button,
  Text,
  Flex,
  useOutsideClick,
  MenuButtonProps,
  Input,
  Tag,
  Image,
  IconButton,
} from '@chakra-ui/react';
import { ArrowLeft, CaretDown, CaretRight, X } from 'phosphor-react';
import { colors } from '@/utils/colors';
import RequiredTag from '@/components/RequiredTag';
import { ChildItems, ParentMenuItem, ResponseInputsV2 } from './types';

export type ListTypes = { key?: string; displayName?: string }[];

interface InputDropdownTypes {
  inputData?: ResponseInputsV2;
  label: string;
  parentMenuItems: ParentMenuItem[];
  childItems: ChildItems;
  handleChange: (
    key: string,
    value: ParentMenuItem,
    output: string,
    indexes?: {
      columnIndex: number;
      rowIndex: number;
      elementIndex: number;
    },
  ) => void;
  type: string;
  subLabel?: string;
  styles?: MenuButtonProps;
}

const InputDropdown = ({
  inputData,
  label,
  parentMenuItems,
  childItems,
  handleChange,
  type,
  subLabel,
  styles,
}: InputDropdownTypes): ReactElement => {
  const { isOpen, onOpen: openDropdown, onClose: closeDropdown } = useDisclosure();
  const [showChildList, setShowChildList] = useState(false);
  const [selectedListData, setSelectedListData] = useState<{ heading: string; list: ParentMenuItem[] }>({
    heading: '',
    list: [],
  });
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: menuRef,
    handler: () => {
      closeDropdown();
      setShowChildList(false);
    },
  });

  const toggleChildList = (): void => {
    setShowChildList(show => !show);
  };

  const handleListSelection = (parentMenuItem: ParentMenuItem): void => {
    const uniqueValues: Set<string> = new Set();
    const uniqueLists: ParentMenuItem[] = [];
    childItems[parentMenuItem.id]?.forEach(obj => {
      if (!uniqueValues.has(obj.id)) {
        uniqueValues.add(obj.id);
        uniqueLists.push(obj);
      }
    });
    const list = uniqueLists;

    if (!list.length) {
      handleChange(type, parentMenuItem, 'output');
      closeDropdown();
      return;
    }
    toggleChildList();
    const { header } = parentMenuItem;
    setSelectedListData({ heading: header, list });
  };

  const indexes = {
    columnIndex: inputData?.columnIndex || 0,
    rowIndex: inputData?.rowIndex || 0,
    elementIndex: inputData?.elementIndex || 0,
  };

  return (
    <Flex w="full" direction="column" gridGap="2" justifyContent="flex-end">
      <Flex gridGap="2" alignItems="center">
        <Text fontSize="sm" color="gray.900">
          {label || ''}
        </Text>
        {// ! `inputData` is important in below condition to avoid rendering Tag when inputData is undefined (in Conditions)
        inputData && !inputData.isOptional && <RequiredTag />}
      </Flex>
      <Flex gridGap="2" alignItems="center">
        <div ref={menuRef as RefObject<HTMLDivElement>} style={{ width: '100%' }}>
          <Menu isOpen={isOpen} autoSelect={false}>
            <MenuButton
              as={Button}
              variant="outline"
              rightIcon={<CaretDown size="16" color={colors.gray[600]} />}
              fontSize="sm"
              fontWeight="light"
              color="gray.900"
              borderRadius="lg"
              textAlign="left"
              w="full"
              {...styles}
              onClick={() => {
                if (isOpen) {
                  closeDropdown();
                } else {
                  openDropdown();
                }
              }}
            >
              <Text overflow="hidden" textOverflow="ellipsis">
                {label} {subLabel ? '-' : ''} {subLabel}
              </Text>
            </MenuButton>
            <MenuList minW="sm" overflowY="auto">
              {showChildList ? (
                <ChildList
                  {...selectedListData}
                  toggleChildList={toggleChildList}
                  closeDropdown={closeDropdown}
                  handleChange={handleChange}
                  type={type}
                  indexes={{
                    columnIndex: inputData?.columnIndex || 0,
                    rowIndex: inputData?.rowIndex || 0,
                    elementIndex: inputData?.elementIndex || 0,
                  }}
                  parentMenuItems={parentMenuItems}
                  subLabel={subLabel ?? ''}
                />
              ) : (
                <>
                  {parentMenuItems.map(parentMenuItem => (
                    <MenuItem
                      key={parentMenuItem.id}
                      py="3"
                      px="4"
                      bg={parentMenuItem.header === selectedListData.heading && subLabel ? 'white.50' : 'white'}
                      icon={childItems[parentMenuItem.id] ? <CaretRight size="16" color={colors.gray[600]} /> : <></>}
                      flexDirection="row-reverse"
                      sx={{ '& :first-child': { marginInlineEnd: '0' } }}
                      fontSize="sm"
                      fontWeight="medium"
                      color="gray.800"
                      _hover={{ textDecoration: 'none' }}
                      onClick={() => handleListSelection(parentMenuItem)}
                    >
                      <Text fontWeight="normal">{parentMenuItem.platformDisplayName || parentMenuItem.header}</Text>
                      {parentMenuItem?.subHeader ? (
                        <Text fontSize="xs" fontWeight="light" color="gray.600">
                          {parentMenuItem.subHeader}
                        </Text>
                      ) : null}
                    </MenuItem>
                  ))}
                </>
              )}
            </MenuList>
          </Menu>
        </div>
        <IconButton
          aria-label="reset"
          title="Reset"
          icon={<X />}
          size="sm"
          color="red.300"
          background="transparent"
          _focus={{ boxShadow: 'none' }}
          onClick={() => handleChange(type, parentMenuItems[0], '..', indexes)}
        />
      </Flex>
    </Flex>
  );
};

const ChildList = ({
  heading,
  list,
  closeDropdown,
  toggleChildList,
  handleChange,
  type,
  indexes,
  parentMenuItems,
  subLabel,
}: {
  heading: string;
  list: ParentMenuItem[];
  closeDropdown: () => void;
  toggleChildList: () => void;
  handleChange: (
    key: string,
    value: ParentMenuItem,
    output: string,
    indexes?: {
      columnIndex: number;
      rowIndex: number;
      elementIndex: number;
    },
  ) => void;
  type: string;
  indexes?: {
    columnIndex: number;
    rowIndex: number;
    elementIndex: number;
  };
  parentMenuItems: ParentMenuItem[];
  subLabel: string;
}): ReactElement => {
  const [searchList, setSearchList] = useState(list);

  function handleSearch(e: ChangeEvent<HTMLInputElement>): void {
    const searchedLists = list.filter(item => {
      const inputValue = e.target.value.toLowerCase();
      const headerValue = item.header.toLowerCase();
      return headerValue.includes(inputValue);
    });
    setSearchList(searchedLists);
  }

  return (
    <>
      <Box
        py="2"
        display="flex"
        alignItems="center"
        px="4"
        cursor="pointer"
        aria-label="Back"
        gridGap="2"
        fontSize="sm"
        fontWeight="medium"
        borderRadius="none"
        _hover={{ bg: 'white' }}
        onClick={toggleChildList}
      >
        <ArrowLeft size={16} color={colors.gray[600]} />
        {heading}
      </Box>
      <Box>
        <Box px="3">
          <Box my="4" b="1" borderColor="gray.400" marginY="2">
            <Input
              autoFocus
              onChange={handleSearch}
              fontSize="md"
              _hover={{ cursor: 'pointer' }}
              _focus={{ outline: 'none' }}
              textAlign="left"
              fontWeight="500"
              aria-label="Search"
              placeholder="Search"
              border="1px"
              borderColor="gray.200"
            />
          </Box>
        </Box>
        <Box maxH="40" overflowY="auto">
          {searchList.length ? (
            searchList.map(item => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                key={item.id}
                flexDirection="row"
                sx={{ '& :first-child': { marginInlineEnd: '0' } }}
                py="2"
                px="4"
                fontSize="sm"
                fontWeight="medium"
                bg={item.subHeader === subLabel ? 'white.50' : 'white'}
                _hover={{ bg: 'gray.100' }}
                cursor="pointer"
                onClick={() => {
                  const parentItem = parentMenuItems.find(menuItem => menuItem.header === heading);
                  let output = '';
                  if (heading === 'User Input') {
                    output = `input.start_ghdvng.${item.id}`;
                  } else if (heading === 'Status') {
                    output = `${item.id}`;
                  } else {
                    output = `source.${parentItem?.id}.${item.id}`;
                  }
                  handleChange(type, { ...item }, output, indexes);
                  closeDropdown();
                }}
              >
                {item.header}
                <Tag py="1" fontSize="xs" color="gray.600" bg="white.50">
                  {item.id}
                </Tag>
              </Box>
            ))
          ) : (
            <Flex align="center" direction="column" py="30px">
              <Image src="/assets/not-found-2.svg" w="20" />
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
};

export default InputDropdown;

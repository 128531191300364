import React, { ReactElement, useMemo } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { Copy } from 'phosphor-react';
import { ToastMessage } from '@/components/copy-button/types';
import { bureauPlatformApi } from '@/utils/api';

const CopyQuickLinkCreationCurl = ({ workflowId }: { workflowId: string }): ReactElement => {
  const toast = useToast();

  const getCopyUrl = useMemo((): string => {
    const getQuickLinkCurl = (): string => {
      const url = bureauPlatformApi.defaults.baseURL;
      return `curl --location --request POST '${url}/workflows/quicklink' \\
        --header 'Authorization: ${bureauPlatformApi.defaults.headers.common.Authorization}' \\
        --header 'x-bureau-auth-org-id: ${bureauPlatformApi.defaults.headers.common['X-Bureau-Auth-Org-ID']}' \\
        --header 'Content-Type: application/json' \\
        --data-raw '{
            "workflowId": "${workflowId}",
            "name": "",
            "transactionLimit": 1,
            "isEnabled": true
        }'`;
    };
    return getQuickLinkCurl();
  }, [workflowId]);

  const copyText = (): void => {
    toast({
      title: ToastMessage.COPIED_TO_CLIPBOARD,
      status: 'info',
      duration: 1000,
      isClosable: true,
    });
    navigator.clipboard.writeText(getCopyUrl as string);
  };

  return (
    <Button
      variant="link"
      leftIcon={<Copy />}
      mr="8"
      color="blue.400"
      fontSize="12"
      fontWeight="400"
      _hover={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      onClick={() => copyText()}
    >
      Configured cURL
    </Button>
  );
};

export default CopyQuickLinkCreationCurl;

import React, { ReactElement, useMemo, useState } from 'react';
import {
  Flex,
  Text,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Input,
  Select,
  FormHelperText,
  Box,
  Checkbox,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { Header, Heading } from '@/layout/Header';
import { CaretRight, ArrowSquareOut, Info, Repeat } from 'phosphor-react';
import { RHS, SearchAndFilters } from '@/components/platform-search-filter/SearchAndFilterWrapper';
import { useFormik } from 'formik';
import MultipleFileUploadWrapper from '@/screens/workflowv2/components/MultipleFileUploadWrapper';
import FactsLoader from '@/components/loader/Loader';
import * as Yup from 'yup';
import TagsInput from '@/components/tag-input/TagsInput';
import PlatformTooltip from '@/components/tooltip/PlatformTooltip';
import { getLatency } from '@/utils/utils';
import { AxiosError } from 'axios';
import { useDocumentation } from '@/queries/UseDocumentation';
import { APIInputParameters, useFetchApiCatalogue } from '../hooks/useFetchApiCatalogue';
import TryoutOutPutWrapper from './TryoutOutPutWrapper';
import { useTryoutService } from '../hooks/useTryoutService';
import TryoutTabs from './TryoutTabs';
import { useCountdown } from '../hooks/useCountdown';
import AsyncTryoutZerostateWrapper from '../assets/AsyncTryoutZerostateWrapper';
import { useAsyncResponse } from '../hooks/useAsyncResponse';

export type ApiTryoutParams = {
  category: string;
  apiName: string;
};

type NestedJson = {
  [key: string]: unknown;
};

const CONSENT_KEYS = ['consent', 'consentText', 'consentType', 'custID'];

const ApiTryoutWrapper = (): React.ReactElement => {
  const history = useHistory();
  const { category } = useParams<ApiTryoutParams>();

  return (
    <Flex direction="column" w="full">
      <Header>
        <Heading>
          <Heading.Title>
            <Breadcrumb spacing="8px" separator={<CaretRight style={{ color: 'gray.500' }} size={18} />}>
              <BreadcrumbItem color="gray.500">
                <BreadcrumbLink onClick={() => history.replace(`/documentation?category=${category}`)}>
                  API Explorer
                </BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem color="gray.900" isCurrentPage>
                <span>Tryout API</span>
              </BreadcrumbItem>
            </Breadcrumb>
          </Heading.Title>
        </Heading>
      </Header>
      <DocumentationTryout />
    </Flex>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const documents = [
  'frontUrl',
  'backUrl',
  'document',
  'file',
  'frontUrl',
  'backUrl',
  'front',
  'back',
  'image1',
  'image2',
  'image_url',
  'selfie',
  'imageURL',
  'back_url',
];

export type InputTypes = boolean | number | string[] | object | string;

const DocumentationTryout = (): React.ReactElement => {
  const { category, apiName } = useParams() as ApiTryoutParams;
  const { data: apiCatalogueData } = useFetchApiCatalogue();
  const [initialTime, setInitialTime] = useState(Date.now());
  const [latency, setLatency] = useState('');

  const {
    apiResponse,
    statusCode,
    isProcessing,
    processingData,
    handleAsyncResponse,
    setApiResponse,
    setStatusCode,
    handleRetry: hookHandleRetry,
  } = useAsyncResponse(setLatency, initialTime);

  const { mutateAsync: tryoutService, isLoading: isTryoutLoading } = useTryoutService({
    onSuccess: data => {
      handleAsyncResponse(data, apiData);
    },
    onError: (error: AxiosError) => {
      const errorStatusCode = error.response?.status || '400';
      setStatusCode(errorStatusCode as string);
      setApiResponse(error.response?.data?.error || error.response?.data || error.message);
      setLatency(getLatency(initialTime, Date.now()));
    },
  });

  const { data: docsUrl } = useDocumentation();

  const apiData = apiCatalogueData?.[category]?.[apiName];
  const endpoint = apiData?.path || '';
  const userInputs = apiData?.input_fields.filter(input => !input.isHiddenOnPlatform);

  const getInitialValue = (inputType: string): InputTypes => {
    switch (inputType) {
      case 'boolean':
        return false;
      case 'number':
        return 0;
      case 'array':
        return [];
      case 'object':
        return {};
      default:
        return '';
    }
  };

  const formInitialValues = useMemo(
    () =>
      userInputs?.reduce(
        (initialValuesObj: { [key: string]: InputTypes }, input) => ({
          ...initialValuesObj,
          [input.key]: getInitialValue(input.type) || '',
        }),
        {},
      ) ?? {},
    [userInputs],
  );

  const validationSchema = Yup.object().shape(
    userInputs?.reduce((validationObj: Record<string, Yup.AnySchema>, input) => {
      let fieldValidation: Yup.AnySchema;

      switch (input.type?.toLowerCase()) {
        case 'number':
          fieldValidation = Yup.number();
          break;
        case 'boolean':
          fieldValidation = Yup.boolean();
          break;
        case 'array':
          fieldValidation = Yup.array().of(Yup.mixed());
          if (!input.isOptional) {
            fieldValidation = fieldValidation.required(`At least one ${input.displayName} is required`);
          }
          break;
        case 'object':
          fieldValidation = Yup.object();
          break;
        case 'file':
          fieldValidation = Yup.string();
          break;
        default:
          fieldValidation = Yup.string();
      }

      if (input.validationPattern && input.type?.toLowerCase() === 'string') {
        fieldValidation = (fieldValidation as Yup.StringSchema).matches(
          new RegExp(input.validationPattern),
          `Invalid format for ${input.displayName}`,
        );
      }

      if (!input.isOptional) {
        fieldValidation = fieldValidation.required(`${input.displayName} is required`);
      }

      return {
        ...validationObj,
        [input.key]: fieldValidation,
        ...(CONSENT_KEYS.includes(input.key)
          ? {
              consent: Yup.boolean()
                .oneOf([true], 'consent must be accepted')
                .required('consent is required'),
              consentText: Yup.string(),
              consentType: Yup.string(),
            }
          : {}),
      };
    }, {}) || {},
  );

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      // Touch all fields to show validation errors
      Object.keys(values).forEach(key => {
        formik.setFieldTouched(key, true);
      });

      if (!formik.isValid) {
        setSubmitting(false);
        return;
      }

      // Filter out values that are the same as the initial values
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([key, value]) => {
          if (Array.isArray(value)) {
            return value.length > 0 || (formInitialValues[key] as string[]).length > 0;
          }
          if (typeof value === 'object' && value !== null && typeof formInitialValues[key] === 'object') {
            return JSON.stringify(value) !== JSON.stringify(formInitialValues[key]);
          }
          return value !== formInitialValues[key];
        }),
      );

      if (endpoint) {
        setInitialTime(Date.now());
        tryoutService({ endpoint, data: filteredValues });
      }

      setSubmitting(false);
    },
  });

  const handleConsentToggle = (isChecked: boolean): void => {
    formik.setFieldValue('consent', isChecked);
    formik.setFieldValue(
      'consentText',
      isChecked ? 'I approve BureauID to capture and process user data based on user consent' : '',
    );
    formik.setFieldValue('consentType', isChecked ? 'explicit' : '');
  };

  const renderUserInputs = (input: APIInputParameters): ReactElement => {
    const inputKey = input.key;
    const inputType = input.type?.toLowerCase();

    // Check if the input is one of the consent-related fields
    if (CONSENT_KEYS.includes(inputKey)) {
      return <></>; // Do not render these inputs individually
    }

    // const isFileInput = documents.includes(input.key) || inputType === 'file';
    const isFileInput = inputType === 'file' || input.key === 'file' || documents.includes(input.key);
    const isDropdown = inputType === 'boolean' || input.allowedValues?.length;
    const isTextInput = inputType === 'string' || inputType === 'number';
    const isArrayInput = inputType === 'array';

    if (isFileInput) {
      return (
        <MultipleFileUploadWrapper
          key={inputKey}
          formValue={formik.values[inputKey] as string}
          setFormValue={(imageUrl: string) => formik.setFieldValue(inputKey as string, imageUrl)}
        />
      );
    }

    if (isArrayInput) {
      return (
        <TagsInput
          placeholder={input.example || ''}
          value={formik.values[inputKey] ? (formik.values[inputKey] as string[]) : []}
          onChange={itemTag => {
            formik.setFieldValue(inputKey, itemTag);
          }}
          maxH="60px"
          overflowY="auto"
          bg="white"
        />
      );
    }

    if (isDropdown) {
      return (
        <Select
          key={inputKey}
          name={inputKey}
          placeholder={`Select ${input.displayName}`}
          value={formik.values[inputKey] !== undefined ? `${formik.values[inputKey]}` : ''}
          onChange={e => {
            const selectedValue = e.target.value;
            if (selectedValue === 'true' || selectedValue === 'false')
              formik.setFieldValue(inputKey as string, selectedValue === 'true');
            else {
              formik.setFieldValue(inputKey as string, selectedValue);
            }
          }}
          onBlur={formik.handleBlur}
          fontSize="sm"
          fontWeight="light"
          color="gray.900"
          borderRadius="lg"
          bg="white"
          _placeholder={{ color: 'gray.500' }}
        >
          {input?.allowedValues?.map(value => (
            <option key={value} value={value}>
              {value.toString()}
            </option>
          ))}
        </Select>
      );
    }

    if (isTextInput) {
      return (
        <Input
          key={inputKey}
          _placeholder={{ fontSize: 'sm', fontWeight: 300, lineHeight: 'normal' }}
          fontSize="sm"
          type={inputType === 'number' ? 'number' : 'text'}
          placeholder={input.example ? input.example : `Enter ${input?.displayName}`}
          name={inputKey as string}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[inputKey] as string | number}
          bg="white"
        />
      );
    }
    return <></>;
  };

  const resetFormAndResponse = (): void => {
    formik.resetForm({ values: formInitialValues });
    setApiResponse(null);
    setStatusCode('200');
  };

  const [isRefreshDisabled, setIsRefreshDisabled] = useState(false);

  const { countdown, startCountdown } = useCountdown({
    initialSeconds: 10,
    onComplete: () => setIsRefreshDisabled(false),
  });

  const handleRetry = async (): Promise<void> => {
    if (!processingData || isRefreshDisabled) return;

    setIsRefreshDisabled(true);
    await hookHandleRetry(isRefreshDisabled, startCountdown);
  };

  return (
    <Flex h="calc(100% - 73px)" direction="column" gridGap="5" p={5}>
      <SearchAndFilters>
        <RHS>
          <RHS.Buttons>
            <Button
              rightIcon={<ArrowSquareOut />}
              onClick={() => {
                const authToken = docsUrl?.url.split('?')[1] as string;
                window.open(`https://docs.bureau.id/reference/${apiName}/?${authToken}`, '_blank');
              }}
              fontWeight="light"
            >
              Detailed Documentation
            </Button>
          </RHS.Buttons>
        </RHS>
      </SearchAndFilters>
      <Flex h="calc(100% - 60px)" gridGap="10">
        <Flex direction="column" gridGap="3" flex={1}>
          <Text fontSize="20px" fontWeight="medium" color="gray.800">
            {apiData?.title}
          </Text>
          <Box
            ref={el => {
              const htmlElement = el as HTMLDivElement;
              if (htmlElement) {
                (htmlElement as HTMLDivElement).innerHTML = apiData?.description || '';
              }
            }}
            fontSize="sm"
            fontWeight="light"
            color="gray.600"
          />
        </Flex>
        <Flex h="full" flex={3.5} bg="white" p="5" gridGap="5" borderRadius="lg">
          <Flex p="5" flex={1} direction="column" gridGap="5" bg="white.50" borderRadius="lg">
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              Give input to tryout API
            </Text>
            <form
              style={{ flex: 1, maxHeight: 'calc(100% - 41px)', overflowY: 'auto' }}
              id="tryout"
              onSubmit={formik.handleSubmit}
            >
              <VStack spacing="6" h="full" overflow="auto">
                {userInputs?.map(input =>
                  input && !CONSENT_KEYS.includes(input.key) ? (
                    <FormControl key={input.key} isInvalid={!!formik.errors[input.key] && !!formik.touched[input.key]}>
                      <FormLabel
                        alignItems="center"
                        gridGap="1"
                        fontSize="sm"
                        fontWeight="400"
                        lineHeight="normal"
                        color="gray.900"
                        display="flex"
                      >
                        {input.displayName}
                        {(input.platformDescription || input.description) && (
                          <PlatformTooltip description={input.platformDescription || input.description}>
                            <Info />
                          </PlatformTooltip>
                        )}
                        {!input.isOptional && <span style={{ color: 'red' }}>*</span>}
                      </FormLabel>
                      {renderUserInputs(input)}
                      {formik.errors[input.key] && formik.touched[input.key] && (
                        <FormHelperText fontSize="xs" color="red.500">
                          {formik.errors[input.key]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <></>
                  ),
                )}
              </VStack>
            </form>
            {userInputs?.some(input => CONSENT_KEYS.includes(input.key)) && (
              <FormControl
                display="flex"
                gap={2}
                isInvalid={!!formik.errors.consent && !!formik.touched.consent}
                ml="2"
              >
                <Checkbox
                  id="consent-checkbox"
                  isChecked={Boolean(formik.values.consent)}
                  onChange={e => handleConsentToggle(e.target.checked)}
                  borderColor="blue.500"
                />
                <Text fontSize="sm" fontWeight="light" color="gray.600" ml="2">
                  I approve BureauID to capture and process data
                  <Box as="span" color="red.500" ml="1">
                    *
                  </Box>
                </Text>
              </FormControl>
            )}
            <Button
              type="submit"
              form="tryout"
              colorScheme="blue"
              fontSize="sm"
              fontWeight="light"
              borderRadius="lg"
              // _hover={{ opacity: '0.85' }}
              isDisabled={
                (userInputs?.some(input => CONSENT_KEYS.includes(input.key)) && !formik.values.consent) ||
                isRefreshDisabled
              }
            >
              Submit
            </Button>
          </Flex>
          <Flex flex={1.57} w="full">
            {isTryoutLoading && <FactsLoader />}
            {!isTryoutLoading && isProcessing && (
              <Flex p="5" flex={1} direction="column" align="center" justify="center" textAlign="center">
                <Box mb={6}>
                  <AsyncTryoutZerostateWrapper />
                </Box>
                <Text fontSize="sm" fontWeight="light" color="gray.600">
                  This API runs asynchronously, so results may take a moment. Thanks for your patience!
                </Text>
                <Text fontSize="sm" fontWeight="light" color="gray.600" mb="5">
                  {countdown > 0
                    ? `You can refresh again in ${countdown} seconds`
                    : 'Click refresh now to check results'}
                </Text>
                <Button
                  leftIcon={<Repeat />}
                  onClick={handleRetry}
                  colorScheme="blue"
                  form="tryout"
                  fontSize="sm"
                  fontWeight="light"
                  borderRadius="lg"
                  // _hover={{ opacity: '0.85' }}
                  isDisabled={isRefreshDisabled}
                  // isLoading={isRefreshDisabled}
                >
                  Refresh
                </Button>
              </Flex>
            )}
            {!isTryoutLoading && !isProcessing && apiResponse && (
              <TryoutTabs
                response={apiResponse as Record<string, unknown>}
                onButtonClick={resetFormAndResponse}
                statusCode={statusCode}
                latency={latency}
              />
            )}
            {!isTryoutLoading && !isProcessing && !apiResponse && (
              <TryoutOutPutWrapper category={category} apiName={apiName} statusCode={statusCode} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ApiTryoutWrapper;

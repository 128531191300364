import { OptionType } from '@bureau/components';
import { subDays } from 'date-fns';

export const dateFilterAllOptions: OptionType[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: subDays(new Date(), 1).toISOString(),
    label: 'Last 24 Hrs',
  },
  {
    value: subDays(new Date(), 6).toISOString(),
    label: 'Last 7 days',
  },
  {
    value: subDays(new Date(), 30).toISOString(),
    label: 'Last 30 days',
  },
  {
    value: subDays(new Date(), 60).toISOString(),
    label: 'Last 60 days',
  },
  {
    value: subDays(new Date(), 90).toISOString(),
    label: 'Last 90 days',
  },
];

export const apiLogDateFilterOption = [
  {
    value: subDays(new Date(), 1).toISOString(),
    label: 'Last 24 Hrs',
  },
  {
    value: subDays(new Date(), 6).toISOString(),
    label: 'Last 7 days',
  },
];

// will be combined with date options once the date select component is implemented everywhere.
export const customDateOption = { label: 'customDate', value: 'customDate' };

export const mobileNumberRegex = /^[6-9]\d{9}$/;

export const REQUIRED_FIELD = 'Required Field';

export const INVALID_URL = 'Not a valid url';

export const bannerHeight = 0;

export const commonHeaderHeight = 74;

export const headerTabsHeight = 49;

export const headerWithTabsHeight = commonHeaderHeight + headerTabsHeight;

export const bulkVerificationFooter = 88;

export const listHeaderHeight = 153;

export const workflowListHeaderHeight = '190px';

export const UserListHeaderHeight = 44;

export const ApiKeyListHeaderHeight = 189;

export const entityHeaderListHeight = 86;

export const transactionsListTableHeaderHeight = 93;

export const transactionsFilterHeight = 91;

export const transactionListHeaderHeight = '236px';

export const blockListTableFilterHeight = 60;

export const webHookListHeaderHeight = '190px';

export const logsListHeaderHeight = '190px';

export const BulkQueryBannerHeight = 73;

export const RiskScoreDemoHeaderHeight = '97';

export const pageLimit = '20';

export const pageLimits: OptionType[] = [
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '50', value: '50' },
];

export const selectAllOption = { label: 'All', value: '*' };

export const DATE_FORMAT = 'dd-MM-yyyy HH:mm:ss';

export const DATE_FORMAT_WITHOUT_SECONDS = 'dd-MM-yyyy HH:mm';

export const DATE_FORMAT_WITHOUT_TIME = 'dd-MM-yyyy';

export const TIMELINE_DATE_FORMAT = `MMMM dd',' yyyy`;

export const TIMELINE_TIME_FORMAT = `hh':'mm aa 'IST'`;

export const fileSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const validTypes = ['.csv', 'application/vnd.ms-excel', 'text/csv'];

export const imageValidTypes = ['image/png', 'image/jpeg', 'image/jpg'];

export const dateString = `${new Date().toISOString()}`;

const curDateFormatted: string = new Date()
  .toISOString()
  .replace(/T.*/, '')
  .split('-')
  .reverse()
  .join('-');

export const DEVICE_ENTITY_DOWNLOAD_FILENAME = `Device-Entity-${curDateFormatted}`;

export const billingFAQs = [
  {
    id: 1,
    question: 'What is the best plan for me?',
    answer:
      'Our team of CGI experts and game tech enthusiasts has come together to provide you with automated content which makes it scalable.',
  },
  {
    id: 2,
    question: 'Which payment methods do you accept?',
    answer:
      'Our team of CGI experts and game tech enthusiasts has come together to provide you with automated content which makes it scalable.',
  },
  {
    id: 3,
    question: 'Can I change or cancel my plan anytime?',
    answer:
      'Our team of CGI experts and game tech enthusiasts has come together to provide you with automated content which makes it scalable.',
  },
  {
    id: 4,
    question: 'How will I be charged for my team?',
    answer:
      'Our team of CGI experts and game tech enthusiasts has come together to provide you with automated content which makes it scalable.',
  },
];

export const ENV_TOGGLE_WHITELIST = [
  { id: '/dashboard/workflow', title: 'Dashboard Workflow' },
  { id: '/dashboard/api', title: 'Dashboard API' },
  { id: '/workflows/templates', title: 'Workflow Templates' },
  { id: '/workflows/my-workflows', title: 'Workflow - My Workflows' },
  { id: '/blocklists', title: 'Blocklists' },
  { id: '/capabilities', title: 'Capability' },
  { id: '/documentation', title: 'APIs' },
  { id: '/reports', title: 'Transaction' },
  { id: '/insights', title: 'Insights' },
  { id: '/bulk-query', title: 'Bulk Query' },
  { id: '/demos', title: 'Demo' },
  { id: '/faqs', title: 'FAQs' },
];
export const ENV_TOGGLE_BLACKLIST = [{ id: 'workflows/create', title: 'Create Workflow' }];

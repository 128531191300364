import { useState, useEffect, useMemo, useCallback } from 'react';
import { AsyncParams, ApiCatalogueResponse, useFetchApiCatalogue } from './useFetchApiCatalogue';
import { useGetMerchantAccessDetails, ServiceConfig, Strategy, V1Service } from './useGetMerchantAccessDetails';

export type ApiDetails = {
  title: string;
  countries: string[];
  path: string;
  credits_required: number;
  ref: string;
};

export type ApiCatalogueData = {
  [category: string]: {
    [apiName: string]: ApiDetails;
  };
};

export type ApiData = {
  name: string;
  country: string[];
  subscription: 'Active' | 'Inactive' | 'Pending';
  credits: string;
  apiName: string;
  isAsync: boolean;
  asyncParams?: AsyncParams;
  endpoint: string;
};

const getApprovedServicesMetadataMap = (
  merchantAccessDetails: ServiceConfig,
): { [key: string]: Strategy | V1Service } => {
  const v1ServicesEntries = merchantAccessDetails?.v1Services.reduce((acc, service) => {
    acc[service.endpoint] = service;
    return acc;
  }, {} as { [key: string]: V1Service });

  const strategiesEntries = merchantAccessDetails?.strategies
    .filter(strategy => strategy.accessStatus === 'approved')
    .reduce((acc, strategy) => {
      acc[strategy.endpoint] = strategy;
      return acc;
    }, {} as { [key: string]: Strategy });

  return { ...v1ServicesEntries, ...strategiesEntries };
};

const getPendingServicesMetadataMap = (merchantAccessDetails: ServiceConfig): { [key: string]: Strategy } => {
  return merchantAccessDetails?.strategies
    .filter(strategy => strategy.accessStatus === 'pending')
    .reduce((acc, strategy) => {
      acc[strategy.endpoint] = strategy;
      return acc;
    }, {} as { [key: string]: Strategy });
};

export type UseApiCatalogueReturnType = {
  leftNavItems: string[];
  selectedCategory: string | null;
  setSelectedCategory: (category: string | null) => void;
  apiData: ApiData[];
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  allAPIsData: ApiCatalogueResponse;
};

export const useApiCatalogue = (): UseApiCatalogueReturnType => {
  const { data: apiCatalogueData, isLoading: isCatalogueLoading, error: catalogueError } = useFetchApiCatalogue();
  const {
    data: merchantAccessDetails,
    isLoading: isMerchantLoading,
    error: merchantError,
  } = useGetMerchantAccessDetails();

  const leftNavItems = useMemo(() => {
    if (!apiCatalogueData) return [];
    return Object.keys(apiCatalogueData).filter(category => category !== 'examples' && category !== 'schemas');
  }, [apiCatalogueData]);

  const approvedServicesMap = useMemo(() => {
    if (merchantAccessDetails) {
      return getApprovedServicesMetadataMap(merchantAccessDetails);
    }
    return {} as { [key: string]: Strategy | V1Service };
  }, [merchantAccessDetails]);

  const pendingServicesMap = useMemo(() => {
    if (merchantAccessDetails) {
      return getPendingServicesMetadataMap(merchantAccessDetails);
    }
    return {} as { [key: string]: Strategy };
  }, [merchantAccessDetails]);

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleCategoryChange = useCallback(
    (category: string | null) => {
      if (category === null || leftNavItems.includes(category)) {
        setSelectedCategory(category);
      }
    },
    [leftNavItems],
  );

  useEffect(() => {
    if (leftNavItems.length > 0 && !selectedCategory) {
      handleCategoryChange(leftNavItems[0]);
    }
  }, [leftNavItems, selectedCategory, handleCategoryChange]);

  const apiData: ApiData[] = useMemo(() => {
    if (!selectedCategory || !apiCatalogueData) return [];

    const categoryData = apiCatalogueData[selectedCategory];
    if (!categoryData) return [];

    return Object.entries(categoryData).map(([apiName, apiDetails]) => ({
      name: apiDetails.title,
      country: apiDetails.countries,
      subscription: (() => {
        if (approvedServicesMap?.[apiDetails.path.substring(1)]) {
          return 'Active';
        }
        if (pendingServicesMap?.[apiDetails.path.substring(1)]) {
          return 'Pending';
        }
        return 'Inactive';
      })(),
      credits: apiDetails.credits_required.toString(),
      endpoint: apiDetails.path,
      apiName,
      isAsync: apiDetails.isAsync,
      asyncParams: apiDetails.asyncParams,
    }));
  }, [selectedCategory, apiCatalogueData, approvedServicesMap, pendingServicesMap]);

  const isLoading = isCatalogueLoading || isMerchantLoading;
  const error = catalogueError || merchantError;

  return {
    leftNavItems,
    selectedCategory,
    setSelectedCategory: handleCategoryChange,
    apiData,
    allAPIsData: apiCatalogueData || {},
    isLoading,
    error,
  };
};

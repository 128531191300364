import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Flex, Input, Tag, TagLabel, TagCloseButton, BoxProps, Text } from '@chakra-ui/react';

interface TagsInputProps extends Omit<BoxProps, 'onChange'> {
  onChange: (item: string[]) => void;
  value: string[];
  placeholder?: string;
}

const TagsInput: React.FC<TagsInputProps> = ({
  onChange,
  value,
  placeholder = 'Press Enter to add tags to list',
  ...boxProps
}) => {
  const [input, setInput] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInput(e.target.value);
  };

  const addTag = useCallback(
    (newTag: string): void => {
      if (newTag.trim()) {
        onChange([...value, newTag.trim()]);
        setInput('');
      }
    },
    [onChange, value],
  );

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && input.trim()) {
      e.preventDefault();
      addTag(input);
    }
  };

  const handleFocus = (): void => {
    setIsFocused(true);
  };

  const handleBlur = (): void => {
    setIsFocused(false);
  };

  const removeTag = (indexToRemove: number): void => {
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  useEffect((): (() => void) => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        if (input.trim()) {
          addTag(input);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addTag, input, onChange, value]);

  return (
    <Box border="1px" borderColor="gray.200" borderRadius="md" p={2} {...boxProps}>
      <Flex flexDirection="column" gap={2}>
        <Flex flexWrap="wrap" alignItems="center" gridRowGap="2" gridColumnGap="1">
          [
          {value.map((tag, index) => (
            <Tag
              key={tag}
              size="sm"
              borderRadius="full"
              variant="solid"
              backgroundColor="gray.300"
              color="gray.900"
              fontSize="12px"
            >
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton fontSize="sm" onClick={() => removeTag(index)} />
            </Tag>
          ))}
          {value.length ? ']' : ''}
          <Input
            ref={inputRef}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            size="sm"
            fontSize="12px"
            variant="unstyled"
            flex={1}
            minWidth="180px"
          />
          {!value.length ? ']' : ''}
        </Flex>
        {!isFocused && input.trim() && (
          <Text fontSize="xs" color="blue.500">
            Click outside or press Enter to add tag
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default TagsInput;

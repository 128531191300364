import { useState } from 'react';
import { bureauPlatformApi } from '@/utils/api';
import { getLatency } from '@/utils/utils';

type NestedJson = {
  [key: string]: unknown;
};

type ProcessingData = {
  apiKey: string;
  asyncEndpoint: string;
  data: NestedJson;
};

type UseAsyncResponseReturn = {
  apiResponse: NestedJson | null;
  statusCode: string;
  isProcessing: boolean;
  processingData: ProcessingData | null;
  handleAsyncResponse: (data: NestedJson, apiData: unknown) => Promise<void>;
  setApiResponse: (response: NestedJson | null) => void;
  setStatusCode: (code: string) => void;
  handleRetry: (isRefreshDisabled: boolean, startCountdown: () => void) => Promise<void>;
  setIsProcessing: (value: boolean) => void;
  setProcessingData: (value: ProcessingData | null) => void;
};

export const useAsyncResponse = (
  setLatency: (latency: string) => void,
  initialTime: number,
): UseAsyncResponseReturn => {
  const [apiResponse, setApiResponse] = useState<NestedJson | null>(null);
  const [statusCode, setStatusCode] = useState<string>('200');
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingData, setProcessingData] = useState<ProcessingData | null>(null);

  const pollAsyncEndpoint = async (
    apiKey: string,
    asyncEndpoint: string,
    data: NestedJson,
  ): Promise<NestedJson | null> => {
    try {
      const response = await bureauPlatformApi.post(asyncEndpoint, {
        [apiKey]: data[apiKey],
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAsyncResponse = async (data: NestedJson, apiData: any): Promise<void> => {
    if (apiData?.isAsync) {
      const { asyncParams } = apiData;
      if (asyncParams) {
        const apiKey = asyncParams.inputs[0].key;
        const asyncEndpoint = asyncParams.endPoint;

        const asyncResponse = await pollAsyncEndpoint(apiKey, asyncEndpoint, data);

        if (asyncResponse) {
          if (asyncResponse.processingStatus === 'processing') {
            setIsProcessing(true);
            setProcessingData({ apiKey, asyncEndpoint, data });
          } else {
            setApiResponse(asyncResponse);
          }
          return;
        }
      }
    }
    setStatusCode('200');
    setApiResponse(data);
    setLatency(getLatency(initialTime, Date.now()));
  };

  const handleRetry = async (isRefreshDisabled: boolean, startCountdown: () => void): Promise<void> => {
    if (!processingData || isRefreshDisabled) return;

    startCountdown();

    const { apiKey, asyncEndpoint, data } = processingData;
    const asyncResponse = await pollAsyncEndpoint(apiKey, asyncEndpoint, data);

    if (asyncResponse?.processingStatus === 'processing') {
      return;
    }

    setIsProcessing(false);
    setProcessingData(null);
    setApiResponse(asyncResponse);
  };

  return {
    apiResponse,
    statusCode,
    isProcessing,
    processingData,
    handleAsyncResponse,
    setApiResponse,
    setStatusCode,
    handleRetry,
    setIsProcessing,
    setProcessingData,
  };
};

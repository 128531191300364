import React, { ReactElement, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  Flex,
  Box,
} from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import CopyButton from '@bureau/components/src/CopyButton/CopyButton';

function DisplayObjectInModal({
  heading,
  content,
  isOpen,
  onClose,
}: {
  heading: string;
  content: object;
  isOpen: boolean;
  onClose: () => void;
}): ReactElement {
  const DOMref = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Box ref={DOMref}>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          scrollBehavior="inside"
          size="3xl"
          portalProps={{ containerRef: DOMref }}
        >
          <ModalOverlay />
          <ModalContent pb="6" pt="4" minH="2xl">
            <ModalHeader>
              <Flex>
                <Heading as="h1" size="md" fontWeight="500" color="blackAlpha.900">
                  {heading}
                </Heading>
                <CopyButton text={JSON.stringify(content)} mx="2" mt="1" _focus={{ boxShadow: 'none' }} />
                <ModalCloseButton />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <ReactJson
                src={content}
                name={heading}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                quotesOnKeys={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                displayArrayKey={false}
                sortKeys
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
export default DisplayObjectInModal;

import { Flex, Box, useToast } from '@chakra-ui/react';
import { Copy } from 'phosphor-react';
import React from 'react';
import ReactJson from 'react-json-view';

interface JsonDisplayProps {
  data: object;
}

const JsonDisplay: React.FC<JsonDisplayProps> = ({ data }) => {
  const toast = useToast();

  const handleCopy = (): void => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    toast({
      title: 'Copied to clipboard',
      description: 'The JSON data has been copied.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      direction="column"
      p="3"
      alignItems="flex-start"
      gridGap="3"
      alignSelf="stretch"
      position="relative"
      maxH="60vh"
      overflowY="scroll"
    >
      <Box position="absolute" top="0" right="0" m="2" onClick={handleCopy} cursor="pointer" zIndex="100">
        <Copy />
      </Box>
      <ReactJson src={data} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} name={false} />
    </Flex>
  );
};

export default JsonDisplay;

import React from 'react';

const AsyncTryoutZerostateWrapper = (): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="115" height="104" viewBox="0 0 115 104" fill="none">
      <path
        d="M91.0516 34.4879C90.8999 31.7943 86.4319 31.6822 86.4319 31.6822C86.4319 31.6822 82.5712 35.5246 81.6996 35.4509C80.8279 35.3778 84.359 31.522 84.359 31.522C82.7473 31.0152 78.1282 32.1464 78.1282 32.1464C78.1282 32.1464 77.2949 37.4038 76.4677 37.1181C75.6411 36.8331 77.4435 32.5009 77.4435 32.5009C75.7501 32.6422 73.8716 34.1858 73.8716 34.1858C71.3747 36.819 74.8846 39.8891 74.8846 39.8891C74.8846 39.8891 74.5562 41.7049 73.0048 43.5396C73.0048 43.5396 72.6722 43.8686 72.2793 44.4643L72.7136 44.5557C72.7733 44.4472 72.8361 44.3425 72.9024 44.2438L72.9037 44.242L72.9067 44.2377C72.9506 44.1768 73.0133 44.0903 73.0913 43.9855V43.9849L73.0919 43.9843C73.2795 43.7528 73.4092 43.5798 73.4951 43.4537L73.4964 43.4525C74.0659 42.7216 74.8626 41.7804 75.2915 41.6385C75.2915 41.6385 75.2574 41.5605 75.2403 41.4192C75.2567 41.4893 75.2738 41.5618 75.2915 41.6385C75.6984 43.3873 79.3574 44.4381 81.5174 44.4503C83.6768 44.4631 82.8977 43.6889 81.9834 42.5053C81.0691 41.3224 78.6807 41.0787 78.0691 40.5616C77.4575 40.0444 78.5449 39.886 79.9026 40.2838C81.2598 40.681 81.6587 42.2068 83.8431 42.8903C86.0275 43.5731 88.6114 39.7392 88.6114 39.7392C86.9716 39.8915 84.3907 38.4595 84.3968 37.9064C84.4029 37.3527 88.4786 38.2694 89.1493 38.2444C89.8199 38.2201 91.2033 37.1815 91.0516 34.4879Z"
        fill="#ECEDF0"
      />
      <path
        d="M76.4385 28.4209C76.4385 28.4209 79.1278 18.7698 85.0705 13.6792C85.288 13.4922 85.5371 13.41 85.7802 13.41C86.4191 13.41 87.0155 13.9783 86.8851 14.7202C86.8821 14.7373 86.879 14.755 86.8754 14.7726C86.5398 16.516 79.7589 23.0447 76.4385 28.4209Z"
        fill="#ECEDF0"
      />
      <path
        d="M75.3926 31.1406C75.3926 31.1406 82.3075 19.6249 91.7204 15.5121C91.8764 15.4438 92.0414 15.411 92.2041 15.411C92.639 15.411 93.0581 15.6442 93.256 16.0548C93.4327 16.4227 93.4552 16.9575 93.0745 17.7287C92.0579 19.7845 81.3724 25.5859 75.3926 31.1406Z"
        fill="#ECEDF0"
      />
      <path
        d="M74.3018 79.8613C74.3018 79.8613 81.9141 76.0457 85.346 76.0457C85.9435 76.0457 86.4144 76.1614 86.6958 76.4331C88.5951 78.2654 74.3018 79.8613 74.3018 79.8613Z"
        fill="#ECEDF0"
      />
      <path
        d="M84.8778 83.1582C80.9525 83.1582 72.5928 80.4914 72.5928 80.4914C72.5928 80.4914 75.5325 80.2331 78.7432 80.2331C82.554 80.2331 86.7472 80.5968 86.8666 82.186C86.9196 82.8902 86.121 83.1582 84.8778 83.1582Z"
        fill="#ECEDF0"
      />
      <path
        d="M24.1597 18.6061C25.9518 13.0795 35.3178 15.2943 35.3178 15.2943C35.3178 15.2943 41.2538 25.4114 43.1098 25.7361C44.9653 26.0613 39.7231 16.0953 39.7231 16.0953C43.3578 15.9229 52.3583 20.81 52.3583 20.81C52.3583 20.81 51.2132 32.2166 53.0917 32.0747C54.9703 31.9334 53.5906 21.9229 53.5906 21.9229C57.0395 23.1454 60.1065 27.3899 60.1065 27.3899C63.8637 34.2414 54.871 38.7124 54.871 38.7124C54.871 38.7124 54.5597 42.6743 56.7867 47.3457C56.7867 47.3457 57.2984 48.2131 57.7906 49.6689L56.8361 49.622C56.7709 49.3625 56.6972 49.111 56.6138 48.8685L56.6119 48.8643L56.6083 48.8539C56.5504 48.7029 56.4663 48.4884 56.3622 48.2271L56.3616 48.2259L56.361 48.2247C56.0978 47.6399 55.9212 47.2086 55.8115 46.898L55.8109 46.8949C55.0251 45.0602 53.8812 42.6639 53.0655 42.1333C53.0655 42.1333 53.1788 41.9896 53.2915 41.7057C53.2197 41.8416 53.1447 41.9841 53.0655 42.1333C51.2601 45.5536 43.0635 45.7358 38.5584 44.579C34.0532 43.4217 36.0999 42.2357 38.6528 40.273C41.2057 38.3098 46.3127 39.1114 47.8702 38.3689C49.4272 37.6264 47.2501 36.7011 44.2045 36.7851C41.1594 36.8698 39.4934 39.829 34.5692 40.055C29.6455 40.281 26.3641 30.8796 26.3641 30.8796C29.6967 32.096 35.8563 30.5269 36.1468 29.3714C36.4374 28.2158 27.4466 27.8912 26.0638 27.4727C24.6805 27.0536 22.3676 24.1328 24.1597 18.6061Z"
        fill="#ECEDF0"
      />
      <path
        d="M88.4155 60.3047C87.5231 57.0105 81.9641 58.04 81.9641 58.04C81.9641 58.04 78.1893 63.8048 77.0917 63.9425C75.9934 64.0796 79.3558 58.3829 79.3558 58.3829C77.2287 58.177 71.8068 60.7854 71.8068 60.7854C71.8068 60.7854 72.1498 67.5108 71.0521 67.3737C69.9538 67.2367 71.0521 61.403 71.0521 61.403C68.9932 62.0207 67.0714 64.4225 67.0714 64.4225C64.669 68.3344 69.8168 71.2168 69.8168 71.2168C69.8168 71.2168 69.885 73.5504 68.4438 76.2269C68.4438 76.2269 68.1185 76.7215 67.7878 77.5615H68.3494C68.3951 77.4111 68.4456 77.2655 68.5017 77.1254L68.5029 77.1229L68.5053 77.1169C68.5437 77.0304 68.5991 76.9067 68.668 76.7569V76.7562L68.6686 76.755C68.8397 76.4194 68.9555 76.1715 69.0292 75.9924L69.0298 75.9906C69.5433 74.9374 70.2834 73.5638 70.7774 73.2763C70.7774 73.2763 70.7153 73.1885 70.6574 73.0186C70.6958 73.1008 70.7354 73.1861 70.7774 73.2763C71.738 75.3351 76.5422 75.6781 79.2188 75.1292C81.8953 74.5798 80.7288 73.8251 79.2876 72.6001C77.8464 71.3745 74.8263 71.6974 73.9345 71.2168C73.0421 70.7368 74.3463 70.2562 76.1305 70.3932C77.9146 70.5309 78.807 72.3151 81.6894 72.5898C84.5719 72.8645 86.7684 67.4426 86.7684 67.4426C84.7777 68.0602 81.2094 66.962 81.0718 66.2755C80.9347 65.5896 86.219 65.6578 87.0425 65.4519C87.8661 65.246 89.3073 63.5989 88.4155 60.3047Z"
        fill="#ECEDF0"
      />
      <path
        d="M30.8023 56.4518C32.7789 52.7068 39.2284 55.4656 39.2284 55.4656C39.2284 55.4656 42.2296 63.472 43.5216 63.9374C44.8136 64.4022 42.2924 56.5907 42.2924 56.5907C44.9238 56.9208 50.7782 61.5533 50.7782 61.5533C50.7782 61.5533 48.5324 69.6024 49.8993 69.7352C51.2655 69.868 51.5238 62.5066 51.5238 62.5066C53.8483 63.815 55.521 67.2456 55.521 67.2456C57.3648 72.6353 50.3488 74.724 50.3488 74.724C50.3488 74.724 49.6306 77.5303 50.6467 81.1631H50.6473C50.6473 81.1631 50.9062 81.8496 51.0779 82.957L50.3981 82.8041C50.3841 82.6092 50.3622 82.4198 50.3323 82.2352L50.3317 82.2316L50.3305 82.2236C50.3074 82.1085 50.2745 81.944 50.2318 81.743V81.7424L50.2312 81.7412C50.1155 81.288 50.0424 80.9566 50.0022 80.7197V80.7179C49.6672 79.3022 49.1445 77.4383 48.6249 76.9552C48.6249 76.9552 48.7242 76.8663 48.8406 76.6762C48.7717 76.7652 48.7005 76.8578 48.6249 76.9552C46.9017 79.1865 40.9925 78.2941 37.9011 76.901C34.8104 75.5079 36.4282 74.9116 38.5066 73.8206C40.585 72.7297 44.1527 73.9425 45.3636 73.6038C46.5746 73.2651 45.1267 72.3282 42.9289 72.0091C40.7312 71.6893 39.1657 73.6068 35.601 73.1548C32.037 72.7029 30.8535 65.5413 30.8535 65.5413C33.0951 66.8308 37.7141 66.4726 38.0674 65.6789C38.4201 64.8852 32.0041 63.5305 31.063 63.0572C30.1219 62.5833 28.8256 60.1973 30.8023 56.4518Z"
        fill="#ECEDF0"
      />
      <path
        d="M28.9941 87.0157C27.9488 84.7497 31.5494 82.7189 31.5494 82.7189C31.5494 82.7189 36.3713 84.1808 37.0511 83.7428C37.7315 83.3043 33.1727 81.6876 33.1727 81.6876C34.2698 80.5741 38.5337 79.4929 38.5337 79.4929C38.5337 79.4929 41.4965 83.4255 42.0484 82.8334C42.5997 82.2413 39.247 79.485 39.247 79.485C40.6913 78.8655 42.8957 79.3108 42.8957 79.3108C46.0784 80.378 44.544 84.4092 44.544 84.4092C44.544 84.4092 45.6003 85.7499 47.664 86.5747H47.6646C47.6646 86.5747 48.0788 86.699 48.6581 87.0151L48.3432 87.2789C48.247 87.2155 48.1501 87.1576 48.0539 87.1059L48.052 87.1046L48.0478 87.1028C47.985 87.0717 47.8961 87.0285 47.7871 86.9767L47.7865 86.9761C47.5324 86.8683 47.3509 86.7836 47.2261 86.7178L47.2248 86.7172C46.4427 86.367 45.3834 85.9442 44.971 86.0143C44.971 86.0143 44.9649 85.9363 44.918 85.8139C44.9345 85.8778 44.9528 85.9442 44.971 86.0143C45.398 87.62 42.8653 90.0662 41.1067 91.0141C39.3481 91.9619 39.6478 90.9915 39.8817 89.6283C40.115 88.2644 41.9595 87.0291 42.2348 86.3408C42.5095 85.6531 41.5532 85.9954 40.6169 86.9097C39.6807 87.8234 40.0176 89.2427 38.5294 90.7491C37.0419 92.2555 33.2665 90.2453 33.2665 90.2453C34.6724 89.6581 36.1587 87.3678 35.9138 86.9189C35.6689 86.4693 32.7372 88.9869 32.1786 89.2579C31.6206 89.529 30.0393 89.2817 28.9941 87.0157Z"
        fill="#ECEDF0"
      />
      <path
        d="M62.7697 63.2933C62.7045 63.3847 62.4298 63.8233 62.7831 64.3185C62.8056 64.3496 62.8209 64.3745 62.83 64.3965C67.0562 66.9359 79.7323 77.2078 79.7323 84.4479V99.4473H41.4756V84.4425C41.4756 77.156 54.3247 66.7203 58.4576 64.3185H58.4583C58.591 64.1333 58.6349 63.9554 58.6349 63.8007C58.6349 63.4919 58.4583 63.2757 58.4583 63.2757C54.3149 60.8202 41.4756 50.4619 41.4756 43.1754V28.1761H79.7323V43.1815C79.7323 50.4558 66.9252 60.8696 62.7697 63.2933Z"
        fill="#D4DFF7"
      />
      <path
        d="M81.9263 103.312H38.9756V99.555C38.9756 99.4959 39.0237 99.4484 39.0828 99.4484H81.8239C81.8806 99.4484 81.9263 99.4941 81.9263 99.5507V103.312Z"
        fill="#E3796A"
      />
      <path
        d="M52.4266 85.3702C52.2305 88.7277 52.8688 95.9691 52.811 99.4473H41.4756V84.4425C41.4756 77.156 54.3247 66.7203 58.4576 64.3185H58.4583C58.591 64.1333 58.6349 63.9554 58.6349 63.8007C58.6349 63.4919 58.4583 63.2757 58.4583 63.2757C54.3149 60.8202 41.4756 50.4619 41.4756 43.1754V28.1761H49.0587L54.4721 29.6575V41.5192C54.4721 41.5192 53.936 44.9365 55.278 48.2916C56.6205 51.6473 59.5023 62.4131 59.4578 63.8117C59.4134 65.2103 52.7616 79.6157 52.4266 85.3702Z"
        fill="#ADC5F9"
      />
      <path
        d="M74.7751 38.1474C72.4561 38.1827 68.4687 38.6006 62.018 40.2282C57.6018 41.3423 49.987 40.5029 45.9594 39.9261C44.5468 39.7238 43.2822 40.8191 43.2822 42.2463V43.975C43.2822 48.7384 56.099 59.0572 59.4882 61.702C60.0017 62.1028 60.302 62.7174 60.302 63.3692V89.858C60.302 90.7626 59.8562 91.605 59.1149 92.1234C56.4499 93.9892 51.9581 99.4482 51.9581 99.4482H68.9444C68.9444 99.4482 64.3893 94.1798 61.8328 92.2245C61.2493 91.778 60.9051 91.0873 60.9051 90.3526V63.3619C60.9051 62.7138 61.1999 62.1016 61.7055 61.6965C65.0277 59.0352 77.5649 48.6787 77.5649 43.9189V40.8952C77.5649 39.3608 76.3089 38.1243 74.7751 38.1474Z"
        fill="#5A75F0"
      />
      <path
        d="M81.819 29.6582H39.0785C39.0219 29.6582 38.9756 29.6119 38.9756 29.5547V25.7945H81.9263V29.551C81.9263 29.6101 81.8781 29.6582 81.819 29.6582Z"
        fill="#E3796A"
      />
      <path
        d="M6.75195 36.327L16.8155 37.7627L10.1369 33.2106L9.79762 34.6701L7.35013 35.113L6.75195 36.327Z"
        fill="#DDE1EC"
      />
      <path d="M16.8155 37.7627L8.44111 33.4445L0.232422 33.0322L16.8155 37.7627Z" fill="#ECEDF0" />
      <path d="M10.1367 33.2106L16.8153 37.7627L11.6455 30.648L10.1367 33.2106Z" fill="#ECEDF0" />
      <path
        d="M112.94 24.2933L114.734 30.0551C114.76 30.1397 114.77 30.2262 114.766 30.3097C114.754 30.6155 114.551 30.8938 114.243 30.9895L104.237 34.104C103.927 34.2002 103.602 34.0839 103.418 33.8378C103.368 33.7714 103.329 33.6959 103.303 33.6124L101.51 27.8537C101.388 27.4596 101.607 27.0417 102.001 26.9192L112.006 23.8047C112.399 23.6823 112.817 23.9004 112.94 24.2933Z"
        fill="#ECEDF0"
      />
      <path
        d="M114.766 30.3097C114.753 30.6155 114.55 30.8938 114.242 30.9895L104.236 34.104C103.926 34.2002 103.601 34.0839 103.417 33.8378L108.439 29.0457L114.766 30.3097Z"
        fill="#E8EBF1"
      />
      <path
        d="M112.94 24.2928L108.561 29.9961L101.602 28.148L101.51 27.8532C101.388 27.4591 101.607 27.0412 102.001 26.9187L112.006 23.8042C112.399 23.6818 112.817 23.8999 112.94 24.2928Z"
        fill="#DDE1EC"
      />
      <path
        d="M77.9391 3.18031L76.6831 7.99308C76.6642 8.06374 76.6338 8.12891 76.5948 8.18556C76.4528 8.39389 76.1897 8.50049 75.932 8.43288L67.5735 6.25156C67.3146 6.18395 67.138 5.9604 67.1166 5.70822C67.1105 5.6406 67.116 5.57056 67.1343 5.50051L68.3897 0.690165C68.4756 0.360622 68.8119 0.163879 69.1402 0.249767L77.4987 2.43108C77.8264 2.51696 78.0238 2.85138 77.9391 3.18031Z"
        fill="#ECEDF0"
      />
      <path
        d="M76.5953 8.18556C76.4534 8.39389 76.1903 8.50049 75.9326 8.43288L67.574 6.25156C67.3152 6.18395 67.1385 5.9604 67.1172 5.70822L72.7194 4.54416L76.5953 8.18556Z"
        fill="#E8EBF1"
      />
      <path
        d="M77.9395 3.18031L72.3891 5.26172L68.3262 0.936259L68.3901 0.690166C68.476 0.360624 68.8123 0.163881 69.1406 0.249769L77.4991 2.43108C77.8268 2.51696 78.0242 2.85138 77.9395 3.18031Z"
        fill="#DDE1EC"
      />
    </svg>
  );
};

export default AsyncTryoutZerostateWrapper;
